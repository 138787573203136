<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Status bearbeiten</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeModal', 'ConfigWorkflowStates');$store.commit('closeConfigWorkflowStates');">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">

              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label> Titel</label>
                    <input class="form-control" type="text" v-model="d.title">
                  </div>
                </div>


                <div class="col-3">
                  <div class="form-group">
                    <label>Reihenfolge</label>
                    <input class="form-control" type="text" v-model="d.ordering">
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label>Zeichen</label>
                    <input class="form-control" type="text" v-model="d.icon"  >
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label>Farbe</label>
                    <input class="form-control" type="color" v-model="d.color">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Für Kunden Sichtbar</label>
                    <select class="form-select" >
                      <option value="1" >{{$t("globals.yes")}}</option>
                      <option value="0">{{$t("globals.no")}}</option>
                    </select>
                  </div>
                </div>

          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Workflow/IssueStates/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "StatesDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs", "getConfigWorkflowStates"])
  },
  data() {
    return {
      d: {
        title: "",
        state: "calc",
        ordering: 0,
      },
      isNew: true,
      stateOptions: [
        {value: "calc", label: "Abzurechnen"},
        {value: "order", label: "Auftragsbasiert"},
        {value: "bug", label: "Kostenlos"},
      ],
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "configs/issueStates/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
          // Load Products
          this.$router.push({name: 'ConfigWorkflowStates'})
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "configs/issueStates/", VueInner.d, {headers}).then(() => {

          this.$store.commit('closeModal', 'ConfigWorkflowStates');
          this.$router.push({name: 'ConfigWorkflowStates'})
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll die Einstellung wirklich gelöscht werden?', 'Einstellung löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "configs/issueStates/" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeConfigWorkflowStates');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gelöscht');
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
         this.d = this.getConfigWorkflowStates

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
<style scoped>
h6.inline {
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
