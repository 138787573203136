<template>
  <div class="card-body customer-list">
    <Sort @sort="resortOrder($event)" />
    <Details v-if="customerActive && customerActive.id"/>

    <perfect-scrollbar ref="scroll" class="mainscroller" :class="(activeCustomer) ? 'active' : ''" >
      <p class="text-sm text-secondary" v-if="!customerList || customerList.data.length === 0">{{$t("list.customerNotFound")}}</p>
      <div :id="'customer_'+customer.id" class="customer-item"
           :class="(activeCustomer && activeCustomer === customer.id) ? 'activeCustomer' : 'notActive'"
           v-for="customer in customerList.data" :key="customer.id">
        <Headline :customer="customer"/>
      </div>
    </perfect-scrollbar>
    <pagination list="customers" listName="customerList" filterName="customersFilter"  />

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Headline from "@/routing/core/Customers/Layouts/Headline";
import Details from "@/routing/core/Customers/Details";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Customers/Layouts/Sort.vue";

export default {
  name: "CustomerList",
  computed: {
    ...mapGetters(["getEmployees", "customerList", "customerActive"])
  },
  data() {
    return {
      selectedCustomer: null,
      activeCustomer: null,

    }
  },
  components: {
    Pagination,
    Details,
    Headline,
    Sort
  },
  methods: {

    getCustomers(){
      this.$store.dispatch("customersFilter");

    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.customers.ordering.value = payload.type;
        this.$store.state.filters.customers.direction.value = payload.direction;
      } else {
        this.$store.state.filters.customers.ordering.value = payload.type
      }
      this.getCustomers();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.getCustomers();
    this.activeCustomer = this.$route.params.id;
    if (this.activeCustomer) {
      this.$store.dispatch("setActiveCustomer", this.activeCustomer);
    } else {
      this.$store.dispatch("setActiveCustomer", null);
    }
    if(this.activeCustomer === undefined){
      this.activeCustomer = null;
      this.$store.dispatch("setActiveCustomer", null);

    }

  },
  watch: {
    // watch router params id
    customerActive() {
      if (this.customerActive && this.customerActive.id) {
        this.activeCustomer = this.customerActive.id;
      } else {
        this.activeCustomer = null;
      }
    },

  }
};
</script>
