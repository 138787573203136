<template>
  <div class="card-header card-header-inline">
    <h6 class="editLine">{{
        taskActive.title
      }}</h6>
    <div class="btn-group float-end">
      <UploadComponent :customer-id="taskActive.crm_customer_id" :task-id="taskActive.id" :compact="true" />
    </div>
  </div>
 <div class="card-body">
  <ul class="fileList list-group">
    <template v-if="attachments" v-for="at in attachments">
      <li class="list-group-item"><i class="fa-duotone fa-paperclip" @click="downloadFile(at.customer_id, at.id)"></i> <i
        class="fa-duotone fa-eye" @click="previewFile(at.customer_id, at.id)"></i>
        {{ at.title }} - {{ at.uploaded_on }}
      </li>
    </template>
  </ul></div>
  <template v-if="file">
    <Modal v-if="modalsList.previewFile">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            {{file.title}}
            <button type="button" class="btn-close" @click="closeFile()"></button>

          </div>
          <div class="modal-body">
            <embed :src="'data:'+file.contentType+';base64,'+file.content">
          </div>
        </div>
      </div>
    </div></Modal>
  </template>
</template>
<script>
import axios from "axios";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "Attachments",
  props: {
    attachments: ""
  },
  components: {
    UploadComponent
  },
  computed: {
    ...mapGetters(["modalsList", "taskActive"])
  },
  data() {
    return {
      file: ''
    };
  },
  methods: {
    previewFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.$store.commit('openModal', 'previewFile');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    downloadFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>
