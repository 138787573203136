<template>
  <div class=" card-body employee-list ">
    <EmployeeDetails v-if="$route.params.id" @close-employee="setEmployee(activeEmployee)" @closedEdit="handleClosedEdit" />
    <Sort @sort="resortOrder($event)" />
    <div  class="product-item h-25" v-for="product in employee.data"
          :key="product.id">
      <Headline :employees="employees"/>
    </div>

    <div  class="employee-item" v-for="employee in employees"
         :key="employee.id">
      <div class="employeeLineWrap" :class="(activeEmployee && activeEmployee.id === employee.id) ? 'active' : ''">
        <div class="lineitem l-title" @click="setEmployee(employee)">{{ employee.e_name }}</div>

        <div class="lineitem l-category" @click="setEmployee(employee)">{{ employee.category }}</div>
        <div class="lineitem l-avatar" @click="setEmployee(employee)">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, employee.id, 'id')" class="sketchAvatar" v-if="employee.id">
        </div>
        <div class="lineitem l-email ">
          <template v-if="employee.e_email">
            <i class="fa-duotone fa-envelope"></i> <span>{{ employee.e_email }}</span>
          </template>
        </div>
        <div class="lineitem l-phone l-title">
          <template v-if="employee.phone">
            <i class="fa-duotone fa-phone"></i> <span>{{ employee.phone }}</span>
          </template>
        </div>
        <div class="lineitem multiFa">
          <template v-if="employee._2fa === 'true'">
        <span tooltip="2FA aktiviert!" position="right">
        <i class="fa-duotone fa-shield-check"></i></span>
          </template>
          <template v-else>
         <span tooltip="2FA nicht aktiv!" position="right">
        <i class="fa-duotone fa-shield"></i></span>
          </template>
        </div>
        <div class="lineitem l-state">
          <template v-if="employee.status === 'active'">
        <span  tooltip="Aktiv" position="right">
        <i class="fa-duotone fa-circle-check"></i></span>
          </template>
          <template v-if="employee.status === 'inactive'">
        <span tooltip="Inaktiv" position="right">
        <i class="fa-duotone fa-circle-xmark"></i></span>
          </template>
          <template v-if="employee.status === 'owner'">
        <span tooltip="owner" position="right">
        <i class="fa-duotone fa-circle-dollar"></i></span>
          </template>
          <template v-if="employee.status === 'support'">
        <span tooltip="support" position="right">
        <i class="fa-duotone fa-circle-question"></i></span>
          </template>
          <template v-if="employee.status === 'deleted'">
        <span tooltip="deleted" position="right">
        <i class="fa-duotone fa-circle-minus"></i></span>
          </template>
          <template v-if="employee.status === 'blocked'">
        <span tooltip="blocked" position="right">
        <i class="fa-duotone fa-circle-"></i></span>
          </template>
        </div>
        <div>
          <template v-if="employee._2fa === 'true'">
        <span tooltip="2fa-Aktiv" position="right">
        <i class="fa-duotone fa-shield-check"></i></span>
          </template>
        </div>

        <div class="lineitem last ml-auto">
          <template v-if="this.$route.params.id === employee.id">
            <i class="fa-duotone fa-x fa-swap-opacity" @click="$router.push({path: '/employees'})"></i>
          </template>
          <template v-else><i class="fa-duotone fa-chevrons-up" @click="setEmployee(employee)"></i></template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EmployeeDetails from "@/routing/core/Employees/Details.vue";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Employees/Layouts/Headline.vue";
import Sort from "@/routing/core/Employees/Layouts/Sort.vue";
import employee from "@/routing/core/Config/Employee.vue";
export default {
  name: "EmployeesOverview",
  components: {
    EmployeeDetails,
    Sort,
    Headline,
  },
  computed: {
    employee() {
      return employee
    },
    ...mapGetters(["getEmployees", "employeeList", "employeeActive"])
  },

  methods: {
    setEmployee(employee) {

      if(this.$route.params.id === null  || this.$route.params.id === employee.id ){
        this.$router.push({path: "/employees"});
        this.activeEmployee = null;
      } else {
        this.$router.push({name: "EmployeeDetails", params: {id: employee.id}});
        this.activeEmployee = employee;
      }
    },
    handleClosedEdit() {
      this.$router.push("/employees");
      this.loadEmployees();
    },
    loadEmployees() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "employee/search", {headers}).then((r) => {
        this.employees = r.data.result.data;
        this.loading = false;
      });
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.products.ordering.value = payload.type;
        this.$store.state.filters.products.direction.value = payload.direction;
      } else {
        this.$store.state.filters.products.ordering.value = payload.type
      }
      this.getProducts();
    },
  },
  data() {
    return {
      employees: [],
      activeEmployee: null,
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
    };
  },
  created() {
    if(this.$route.params.id){
      this.$router.push({name: "EmployeeDetails", params: {id: this.$route.params.id}});
    }
    this.loadEmployees();
    window.addEventListener('newEmployee', this.loadEmployees);
  },
};
</script>

