<template>
  <h3>{{project.title}}</h3>
  <div class="infoBlock"><i
    class="fa-duotone fa-calendar-circle-plus"></i> Erstellt: {{$filters.formatDate(project.crm_created_date)}} | <i
    class="fa-duotone fa-calendar-circle-user"></i> Bearbeitet:
    {{$filters.formatDate(project.crm_modified_date)}}</div>
  <p>{{project.c_firstname}} {{project.c_lastname}} <template v-if="project.c_company">- {{project.c_company}}</template></p>
  <div class="projectDesc" v-html="project.description"></div>
  <div class="projectInfos" style="float: left;
width: 100%;">
    <ul class="infoList" style="width: 100%;">
      <li v-for="(p,k) in project"><label style="width: auto; margin-right: 24px;">{{ k }}</label>{{ p }}
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Details",
  emits: ['refresh-project-list'],
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees",  "modalsList"])
  },
}
</script>
<style scoped>
</style>
