<template>
  <div class="appView customers">
  <List/>
  </div>
</template>
<script>

import List from "@/routing/core/Customers/List";

export default {
  name: "Customers",
  components: {
    List,
  },
  created: function () {

    this.$store.dispatch("customersFilter");
  },


};
</script>
