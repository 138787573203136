<template>
  <div class="eventItem activeProduct">
    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Veranstaltung</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeEvent');$store.commit('closeModal', 'event'); ">
        </button>
        <div class="btn-group float-end">

        <button type="button" class="btn btn-outline-secondary btn-sm" v-if="event.id"
                @click="deleteEvent()"><i
          class="fa-duotone fa-trash"></i> Löschen
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4" @click="updateEvent()"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
      </div>
      <ul class="tabNav">
        <li v-for="cat in eventTabs" :key="cat.name"
            @click="changeTab(cat.name)"
            :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i>
          <br><small>{{ cat.label }}</small>
        </li>
      </ul>
      <template v-if="activeSubtab === 'details'">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8 col-12">
              <div class="form-group">
                <label>Bezeichnung</label>
                <input class="form-control" type="text" v-model="event.title">
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Status</label>

                <select  class="form-select" v-model.number="event.state">
                  <option value="1">Veröffentlicht</option>
                  <option value="2">Inoffiziell</option>
                  <option value="0">Nicht veröffentlicht</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Einleitung/Vorschau</label>
            <textarea class="form-control" v-model="event.preview"/>
          </div>
          <div class="form-group">
            <label>Text</label>
            <Editor2 v-model="event.text"></Editor2>
          </div>
          <!--          <div class="row">-->
          <!--            <div class="col-lg-6 col-12">-->
          <!--              <div class="form-group">-->
          <!--                <label>Tags</label>-->
          <!--                              <VueMultiselect class="small open isMultiselect" :options="event.tags"-->
          <!--                                              v-model="event.tags" :multiple="true" :valueProp="'id'" ref="multiselect_extfollower"-->
          <!--                                              :close-on-select="false" :clear-on-select="false" :preserve-search="true"-->
          <!--                                              :isOpen="true" track-by="id" openDirection="bottom" @close="updateEvent()"-->
          <!--                                              placeholder="Kontaktperson(en) wählen" label="_customerName" :allow-empty="true"-->
          <!--                                              :select-label="'Kontaktperson(en) wählen'"></VueMultiselect>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Startzeitpunkt</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Startzeitpunkt wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.start"></VueDatePicker>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Endzeitpunkt (optional)</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Endzeitpunkt wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.end"></VueDatePicker>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Kategorie</label>
                <select  v-model="event.category_id" class="form-select">
                  <option :value="cat.id" v-for="cat in getConfigs.categories_events" :key="cat.id">{{
                      cat.title
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Anmeldefrist</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Anmeldefrist wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.deadline"></VueDatePicker>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Anmeldung freigegeben ab</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Anmeldung freigegeben ab wählen'" :auto-apply="true"
                               :close-on-auto-apply="true" locale="de" :enable-time-picker="true" cancel-text="X"
                               select-text="Wählen" v-model="event.opentime"
                ></VueDatePicker>
                <small>Leer lassen, wenn sofort</small>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Kulanzzeit bei Checkin</label>
                <input class="form-control" type="number" min="0" step="1" v-model="event.goodwill_hours"/>
                <small>Angabe als Ganzzahl (Stunden), Standard = 1</small>
              </div>
            </div>
          </div>
          <hr>
          <h5>Standort</h5>
          <hr>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Standort</label>
                <input class="form-control" type="text" v-model="event.location_name" size="60"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Adresse</label>
                <input class="form-control" type="text" v-model="event.location" size="60"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Koordinaten LAT</label>
                <input class="form-control" type="text" v-model="event.location_lat" size="60"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Koordinaten LONG</label>
                <input class="form-control" type="text" v-model="event.location_long" size="60"/>
              </div>
            </div>
          </div>
          <hr>
          <h5>Dozent</h5>
          <hr>
          <div class="row">
            <div class="col-lg-5 col-12">
              <div class="form-group">
                <label>Dozent</label>
                <input class="form-control" type="text" v-model="event.trainer" size="60"/>
              </div>
            </div>
            <div class="col-lg-5 col-12">
              <div class="form-group">
                <label>Trainer-Account</label>
                <input class="form-control" type="text" v-model="event.trainer_user" size="60"/>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label>LE</label>
                <input class="form-control" type="number" v-model="event.le"/>
              </div>
            </div>
          </div>
          <hr>
          <h5>Teilnehmer</h5>
          <hr>
          <div class="row">
            <div class="col-lg-5 col-12">
              <div class="form-group">
                <label>Teilnehmer</label>
                <input class="form-control" type="number" min="1" step="1" v-model="event.quota"/>
              </div>
            </div>
            <div class="col-lg-5 col-12">
              <div class="form-group">
                <label>Mindestteilnehmer</label>
                <input class="form-control" type="number" min="0" step="1" v-model="event.min_quota"/>
                <small>Wenn 0, dann keine</small>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label>Warteliste aktivieren</label>
                <select  class="form-select" v-model.number="event.waiting">
                  <option value="1">Ja</option>
                  <option value="0">Nein</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-12">
              <div class="form-group" v-if="event.waiting === 1">
                <label>Max. Warteliste</label>
                <input class="form-control" type="number" step="1" min="0" v-model="event.waiting_quota"/>
              </div>
            </div>
          </div>
          <hr>
          <h5>Preise</h5>
          <hr>
          <div class="row">
            <div class="col-lg-12 col-12">
              <label>Preissystem wählen</label>
              <select  v-model="event.priceField" class="form-select">
                <option v-for="price in pricing" :value="price.name">{{ price.desc }}</option>
              </select>
            </div>
          </div>
          <div class="row" v-if="event.priceField === 'simple'">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <label>Preis</label>
                <input class="form-control" type="text" v-model="event.price" size="60"/>
              </div>
            </div>
          </div>
          <div class="row" v-if="event.priceField === 'extended'">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3 col-3"><label>Preis</label></div>
                <div class="col-lg-3 col-3"><label>Mwst.</label></div>
                <div class="col-lg-3 col-3"><label>Titel</label></div>
                <div class="col-lg-3 col-3"><label>Beschreibung</label></div>
              </div>
              <template v-for="(multiprice, key) in event.multi.prices" :key="key">
                <div class="row">
                  <div class="col-lg-3 mb-2 col-3"><input type="number" v-model="event.multi.prices[key].price"
                                                          class="form-control"
                                                          step="0.01" min="0" placeholder="Preis"/></div>
                  <div class="col-lg-3 mb-2 col-3"><input type="number" v-model="event.multi.prices[key].ust"
                                                          class="form-control"
                                                          step="0.01" min="0" placeholder="Mwst."/></div>
                  <div class="col-lg-3 mb-2 col-3"><input type="text" v-model="event.multi.prices[key].title"
                                                          class="form-control"
                                                          placeholder="Titel"/></div>
                  <div class="col-lg-3 mb-2 col-3"><input type="text" v-model="event.multi.prices[key].desc"
                                                          class="form-control"
                                                          placeholder="Beschreibung"/></div>
                </div>
              </template>
            </div>
          </div>
          <div class="row mb-4" v-if="event.priceField === 'extended'">
            <div class="col-lg-6 col-12">
              <small>Zum löschen einer Zeile, bitte das Titel-Feld leeren. </small>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <button class="btn btn-outline-primary"
                      @click="event.multi.prices.push({price: 0, ust:0, title:'', desc:''})">
                Preis
                hinzufügen
              </button>
            </div>
          </div>
          <div class="row" v-if="event.priceField === 'extended'">
            <div class="col-12">
              <div class="row" v-for="(multioption, key) in event.multi.options" :key="key">
                <div class="col-lg-4 col-4"><label>Pflichtfeld</label>
                  <select  class="form-select" v-model="event.multi.options[key].required">
                    <option v-for="option in options.required" :value="option.value">{{ option.name }}</option>
                  </select>
                </div>
                <div class="col-lg-4 col-4"><label>Typ</label>
                  <select  class="form-select" v-model="event.multi.options[key].mode">
                    <option v-for="option in options.mode" :value="option.value">{{ option.name }}</option>
                  </select>
                </div>
                <div class="col-lg-4 col-4"><label>Text</label>
                  <input type="text" v-model="event.multi.options[key].text" class="form-control"
                         placeholder="Text"/>
                </div>
                <div class="col-12 col-lg-12">
                  <div class="row">
                    <div class="col-lg-4 col-4">
                      <label>Beschreibung</label>
                    </div>
                    <div class="col-lg-4 col-4">
                      <label>Mwst.</label>
                    </div>
                    <div class="col-lg-4 col-4">
                      <label>Preis</label>
                    </div>
                  </div>
                  <div class="row" v-for="(option, okey) in event.multi.options[key].options" :key="okey">
                    <div class="col-lg-4 col-4 mb-2">
                      <input type="text" v-model="event.multi.options[key].options[okey].desc" class="form-control"
                             placeholder="Beschreibung"/>
                    </div>
                    <div class="col-lg-4 col-4 mb-2">
                      <input type="number" v-model="event.multi.options[key].options[okey].ust" class="form-control"
                             placeholder="Mwst"/>
                    </div>
                    <div class="col-lg-4 col-4 mb-2">
                      <input type="number" v-model="event.multi.options[key].options[okey].price" class="form-control"
                             step="0.01" min="0" placeholder="Preis"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-lg-12 col-12 text-end">
                      <button class="btn btn-outline-primary btn-sm"
                              @click="event.multi.options[key].options.push({desc:'', price:0, ust:0})">
                        Option hinzufügen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <button class="btn btn-outline-primary"
                          @click="event.multi.options.push({required:'0', type:'0', text:'', options:[{price: '0', ust:'0', desc:''}]})">
                    Feldgruppe hinzufügen
                  </button>
                  <hr>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Registrierung ermöglichen</label>
                <select  class="form-select" v-model="event.show_payment">
                  <option value="1">Für alle</option>
                  <option value="2">Für Registrierte</option>
                  <option value="0">Nein</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Bezahlschnittstellen benutzen</label>
                <select  class="form-select" v-model.number="event.use_payment">
                  <option value="1">Ja</option>
                  <option value="0">Nein</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Login-Text im Popup</label>
                <textarea class="form-control" v-model="event.login_text"></textarea>
              </div>
              <div class="form-group">
                <label>Sondervereinbarungen</label>
                <Editor2 v-model="event.specialConditions" filter="safehtml"></Editor2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <label>Gebühren Erklärung in der Übersicht</label>
                <textarea class="form-control" v-model="event.priceDetails"></textarea>
              </div>
            </div>
          </div>
          <hr>
          <h5>Verknüpfungen</h5>
          <hr>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>CRM Produkt-Id</label>
                <input class="form-control" type="text" v-model="event.crm_product_id">
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>CRM Produkt-Id für Storno</label>
                <input class="form-control" type="text" v-model="event.crm_product_id_storno">
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'storno'">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group stornoRule">
                <h4>Stornoregelung #1</h4>
                <div class="alert alert-info hint">
                  Standard-Regelungen für Storno-Verhalten. Wird hier kein Datum festgelegt, greift immer die hier
                  festgelegte Regelung (default: 100% Rückerstattung)
                </div>
              </div>
              <div class="form-group">
                <label>Stornofrist</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss"
                               format="yyyy-MM-dd HH:mm:ss"
                               :placeholder="'Stornofrist wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.storno_deadline">
                </VueDatePicker>
              </div>
              <div class="form-group">
                <label>Teilnahmegebühr zurückerstatten?</label>
                <select  class="form-select" v-model="event.storno_refund">
                  <option value="yes">Ja</option>
                  <option value="no">Nein</option>
                </select>
              </div>
              <div class="form-group">
                <label>Stornogebühr in €</label>
                <input class="form-control" type="number" step="0.01" min="0" v-model="event.storno_fee">
              </div>
              <div class="form-group">
                <label>Mail</label>
                <input type="text" class="form-control" v-model="event.storno_hl">
                <br>
                <Editor2 v-model="event.storno_bd"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group stornoRule">
                <h4>Stornoregelung #2</h4>
                <div class="alert alert-info  hint">
                  Zweite Frist für Storno-Verhalten. Endet Frist 1 greift automatisch Regelung #2 (default: 100%
                  Rückerstattung, 10€ Gebühr).
                </div>
              </div>
              <div class="form-group">
                <label>Stornofrist</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss"
                               format="yyyy-MM-dd HH:mm:ss"
                               :placeholder="'Stornofrist wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.storno_deadline2">
                </VueDatePicker>
              </div>
              <div class="form-group">
                <label>Teilnahmegebühr zurückerstatten?</label>
                <select  class="form-select" v-model="event.storno_refund2">
                  <option value="yes">Ja</option>
                  <option value="no">Nein</option>
                </select>
              </div>
              <div class="form-group">
                <label>Stornogebühr in €</label>
                <input class="form-control" type="number" step="0.01" min="0" v-model="event.storno_fee2">
              </div>
              <div class="form-group">
                <label>Mail</label>
                <input type="text" class="form-control" v-model="event.storno2_hl">
                <br>
                <Editor2 v-model="event.storno2_bd"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group stornoRule">
                <h4>Stornoregelung #3</h4>
                <div class="alert alert-info  hint">
                  3. Frist für Storno-Verhalten. Endet Frist 2 greift automatisch Regelung #3 (0%
                  Rückerstattung). Wenn hier ein Datum hinterlegt wird, ist nach Ablauf kein Storno mehr möglich.
                </div>
              </div>
              <div class="form-group">
                <label>Stornofrist</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss"
                               format="yyyy-MM-dd HH:mm:ss"
                               :placeholder="'Stornofrist wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="event.storno_deadline3">
                </VueDatePicker>
              </div>
              <div class="form-group">
                <label>Teilnahmegebühr zurückerstatten?</label>
                <select  class="form-select" v-model="event.storno_refund3">
                  <option value="yes">Ja</option>
                  <option value="no">Nein</option>
                </select>
              </div>
              <div class="form-group">
                <label>Stornogebühr in €</label>
                <input class="form-control" type="number" step="0.01" min="0" v-model="event.storno_fee3">
              </div>
              <div class="form-group">
                <label>Mail</label>
                <input type="text" class="form-control" v-model="event.storno3_hl">
                <br>
                <Editor2 v-model="event.storno3_bd"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group stornoRule">
                <h4>Stornoregelung no-show</h4>
                <div class="alert alert-info hint">
                  No-Show Fee. Wird ausgelöst, wenn im Check-In auf Abwesend gesetzt wird.
                </div>
              </div>
              <div class="form-group">
                <label>Teilnahmegebühr zurückerstatten?</label>
                <select  class="form-select" v-model="event.storno_noshow_refund">
                  <option value="yes">Ja</option>
                  <option value="no">Nein</option>
                </select>
              </div>
              <div class="form-group">
                <label>Stornogebühr in €</label>
                <input class="form-control" type="number" step="0.01" min="0" v-model="event.storno_noshow_fee">
              </div>
              <div class="form-group">
                <label>Mail</label>
                <input type="text" class="form-control" v-model="event.storno_noshow_hl">
                <br>
                <Editor2 v-model="event.storno_noshow_bd"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'attachments'">
        <Attachments :attachments="event.attachments" @update="loadEvent"/>
      </template>
      <template v-if="activeSubtab === 'settings'">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Variablen</label>
                <div class="alert alert-info">
                  <small>##DATE_TITLE## = Titel des Termins,<br> ##DATE_TIME## = Startzeit des Termins,<br>
                    ##DATE_LOCATION## = Ort und Adresse des Termins,<br> ##SALUTATION## = Anrede,<br> ##QUANTITY## =
                    Geuchte Stückzahl,<br> ##FULL_INFO_FORMATTED## = formatierte Anmeldedaten <br> ##PAYMENT_TEXT## =
                    Zahlungsinformationen (aus Plugins) <br> ##PRICE## = Preis</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>BCC-Addressen</label>
                <input class="form-control" type="text" v-model="event.custom_settings.bcc"/>
                <small>Kommasepariert, ohne Leerzeichen mehrere Emails möglich</small>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Lastschrift-Addressen</label>
                <input class="form-control" type="text" v-model="event.custom_settings.debit_mail"/>
                <small>Kommasepariert, ohne Leerzeichen mehrere Emails möglich</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h5>Mail-Einstellung für Registration</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Betreff Registration</label>
                <input class="form-control" type="text" v-model="event.custom_settings.registration_hl"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Text Registration</label>
                <Editor2 v-model="event.custom_settings.registration_bd" filter="safehtml"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h5>Mail-Einstellung für waitingToActive</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Betreff WaitingToActive</label>
                <input class="form-control" type="text" v-model="event.custom_settings.waitingToActive_hl"/>
                <small>Nachrückhinweis. Mail wird verschickt, wenn ein Platz frei wird.</small>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Text WaitingToActive</label>
                <Editor2 v-model="event.custom_settings.waitingToActive_bd" filter="safehtml"/>
                <small>Nachrückhinweis. Mail wird verschickt, wenn ein Platz frei wird.</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Betreff Warteliste</label>
                <input class="form-control" type="text" v-model="event.custom_settings.waiting_hl"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Text Warteliste</label>
                <Editor2 v-model="event.custom_settings.waiting_bd" filter="safehtml"/>
                <small>Wenn leer, wird der Text aus der Registrierungsmail verwendet.</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
              <h5>Mail-Einstellung Änderungshinweise</h5>
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Betreff Änderungshinweis</label>
                <input class="form-control" type="text" v-model="event.custom_settings.sendChanges_hl"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Text Änderungshinweis</label>
                <Editor2 v-model="event.custom_settings.sendChanges_bd"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'required'">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                  <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-suffix-1" autocomplete="off"
                         v-model.number="event.show_suffix">
                  <label class="btn btn-outline-primary" for="event-show-suffix-1">{{$t("globals.yes")}}</label>
                    <input type="radio" :value="0" class="btn-check" id="event-show-suffix-0" autocomplete="off"
                           v-model.number="event.show_suffix">
                    <label class="btn btn-outline-primary" for="event-show-suffix-0">{{$t("globals.no")}}</label>
                  </div>
                    <label>{{$t("events.details.requiredFields.showSuffix")}}</label>

              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_suffix === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_suffix === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-suffix-1" autocomplete="off"
                         v-model.number="event.required_suffix">
                  <label class="btn btn-outline-primary" for="event-required-suffix-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-suffix-0" autocomplete="off"
                         v-model.number="event.required_suffix">
                  <label class="btn btn-outline-primary" for="event-required-suffix-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredSuffix")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-lastname-1" autocomplete="off"
                         v-model.number="event.show_lastname">
                  <label class="btn btn-outline-primary" for="event-show-lastname-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-lastname-0" autocomplete="off"
                         v-model.number="event.show_lastname">
                  <label class="btn btn-outline-primary" for="event-show-lastname-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showSurname")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_lastname === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_lastname === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-lastname-1" autocomplete="off"
                         v-model.number="event.required_lastname">
                  <label class="btn btn-outline-primary" for="event-required-lastname-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-lastname-0" autocomplete="off"
                         v-model.number="event.required_lastname">
                  <label class="btn btn-outline-primary" for="event-required-lastname-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredSurname")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-firstname-1" autocomplete="off"
                         v-model.number="event.show_firstname">
                  <label class="btn btn-outline-primary" for="event-show-firstname-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-firstname-0" autocomplete="off"
                         v-model.number="event.show_firstname">
                  <label class="btn btn-outline-primary" for="event-show-firstname-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showFirstname")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_firstname === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_firstname === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-firstname-1" autocomplete="off"
                         v-model.number="event.required_firstname">
                  <label class="btn btn-outline-primary" for="event-required-firstname-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-firstname-0" autocomplete="off"
                         v-model.number="event.required_firstname">
                  <label class="btn btn-outline-primary" for="event-required-firstname-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredFirstname")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-company-1" autocomplete="off"
                         v-model.number="event.show_company">
                  <label class="btn btn-outline-primary" for="event-show-company-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-company-0" autocomplete="off"
                         v-model.number="event.show_company">
                  <label class="btn btn-outline-primary" for="event-show-company-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showCompany")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_company === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_company === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-company-1" autocomplete="off"
                         v-model.number="event.required_company">
                  <label class="btn btn-outline-primary" for="event-required-company-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-company-0" autocomplete="off"
                         v-model.number="event.required_company">
                  <label class="btn btn-outline-primary" for="event-required-company-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredCompany")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-birthday-1" autocomplete="off"
                         v-model.number="event.show_birthday">
                  <label class="btn btn-outline-primary" for="event-show-birthday-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-birthday-0" autocomplete="off"
                         v-model.number="event.show_birthday">
                  <label class="btn btn-outline-primary" for="event-show-birthday-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showBirthday")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_birthday === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_birthday === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-birthday-1" autocomplete="off"
                         v-model.number="event.required_birthday">
                  <label class="btn btn-outline-primary" for="event-required-birthday-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-birthday-0" autocomplete="off"
                         v-model.number="event.required_birthday">
                  <label class="btn btn-outline-primary" for="event-required-birthday-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredBirthday")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-email-1" autocomplete="off"
                         v-model.number="event.required_email">
                  <label class="btn btn-outline-primary" for="event-required-email-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-email-0" autocomplete="off"
                         v-model.number="event.required_email">
                  <label class="btn btn-outline-primary" for="event-required-email-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredEmail")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-street-1" autocomplete="off"
                         v-model.number="event.show_street">
                  <label class="btn btn-outline-primary" for="event-show-street-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-street-0" autocomplete="off"
                         v-model.number="event.show_street">
                  <label class="btn btn-outline-primary" for="event-show-street-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showStreet")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_street === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_street === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-street-1" autocomplete="off"
                         v-model.number="event.required_street">
                  <label class="btn btn-outline-primary" for="event-required-street-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-street-0" autocomplete="off"
                         v-model.number="event.required_street">
                  <label class="btn btn-outline-primary" for="event-required-street-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredStreet")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-zip-1" autocomplete="off"
                         v-model.number="event.show_zip">
                  <label class="btn btn-outline-primary" for="event-show-zip-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-zip-0" autocomplete="off"
                         v-model.number="event.show_zip">
                  <label class="btn btn-outline-primary" for="event-show-zip-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showZip")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_zip === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_zip === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-zip-1" autocomplete="off"
                         v-model.number="event.required_zip">
                  <label class="btn btn-outline-primary" for="event-required-zip-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-zip-0" autocomplete="off"
                         v-model.number="event.required_zip">
                  <label class="btn btn-outline-primary" for="event-required-zip-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredZip")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-city-1" autocomplete="off"
                         v-model.number="event.show_city">
                  <label class="btn btn-outline-primary" for="event-show-city-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-city-0" autocomplete="off"
                         v-model.number="event.show_city">
                  <label class="btn btn-outline-primary" for="event-show-city-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showCity")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_city === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_city === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-city-1" autocomplete="off"
                         v-model.number="event.required_city">
                  <label class="btn btn-outline-primary" for="event-required-city-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-city-0" autocomplete="off"
                         v-model.number="event.required_city">
                  <label class="btn btn-outline-primary" for="event-required-city-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredCity")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-country-1" autocomplete="off"
                         v-model.number="event.show_country">
                  <label class="btn btn-outline-primary" for="event-show-country-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-country-0" autocomplete="off"
                         v-model.number="event.show_country">
                  <label class="btn btn-outline-primary" for="event-show-country-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showCountry")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_country === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_country === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-country-1" autocomplete="off"
                         v-model.number="event.required_country">
                  <label class="btn btn-outline-primary" for="event-required-country-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-country-0" autocomplete="off"
                         v-model.number="event.required_country">
                  <label class="btn btn-outline-primary" for="event-required-country-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredCountry")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-phone-1" autocomplete="off"
                         v-model.number="event.show_phone">
                  <label class="btn btn-outline-primary" for="event-show-phone-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-phone-0" autocomplete="off"
                         v-model.number="event.show_phone">
                  <label class="btn btn-outline-primary" for="event-show-phone-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showPhone")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_phone === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_phone === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-phone-1" autocomplete="off"
                         v-model.number="event.required_phone">
                  <label class="btn btn-outline-primary" for="event-required-phone-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-phone-0" autocomplete="off"
                         v-model.number="event.required_phone">
                  <label class="btn btn-outline-primary" for="event-required-phone-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredPhone")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-mobile-1" autocomplete="off"
                         v-model.number="event.show_mobile">
                  <label class="btn btn-outline-primary" for="event-show-mobile-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-mobile-0" autocomplete="off"
                         v-model.number="event.show_mobile">
                  <label class="btn btn-outline-primary" for="event-show-mobile-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showMobile")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_mobile === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_mobile === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-mobile-1" autocomplete="off"
                         v-model.number="event.required_mobile">
                  <label class="btn btn-outline-primary" for="event-required-mobile-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-mobile-0" autocomplete="off"
                         v-model.number="event.required_mobile">
                  <label class="btn btn-outline-primary" for="event-required-mobile-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredMobile")}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-show-message-1" autocomplete="off"
                         v-model.number="event.show_message">
                  <label class="btn btn-outline-primary" for="event-show-message-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-show-message-0" autocomplete="off"
                         v-model.number="event.show_message">
                  <label class="btn btn-outline-primary" for="event-show-message-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.showMessage")}}</label>
              </div>
            </div>
            <div class="col-lg-1 col-12 d-none d-lg-block"><template v-if="event.show_message === 1"><i class="fa-duotone fa-chevron-double-right fa-xl "></i></template></div>
            <div class="col-lg-7 col-12">
              <div class="form-group" v-if="event.show_message === 1">
                <div class="btn-group me-3">
                  <input type="radio" :value="1" class="btn-check" id="event-required-message-1" autocomplete="off"
                         v-model.number="event.required_message">
                  <label class="btn btn-outline-primary" for="event-required-message-1">{{$t("globals.yes")}}</label>
                  <input type="radio" :value="0" class="btn-check" id="event-required-message-0" autocomplete="off"
                         v-model.number="event.required_message">
                  <label class="btn btn-outline-primary" for="event-required-message-0">{{$t("globals.no")}}</label>
                </div>
                <label>{{$t("events.details.requiredFields.requiredMessage")}}</label>

              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'subevents'">
        <Subevents :event="event" v-if="event"></Subevents>
      </template>
      <template v-if="activeSubtab === 'registrations'">
        <Registrations/>
      </template>
      <template v-if="activeSubtab === 'checkins'">
        <Checkins :subevents="event.subevents"/>
      </template>
      <template v-if="activeSubtab === 'agenda'">
        <Agenda/>
      </template>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Attachments from "@/routing/core/Events/Layouts/Attachments.vue";
import Headline from "@/routing/core/Events/Layouts/Headline.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import Subevents from "@/routing/core/Events/Layouts/Subevents.vue";
import Registrations from "@/routing/core/Events/Layouts/Registrations.vue";
import Checkins from "@/routing/core/Events/Layouts/Checkins.vue";
import Agenda from "@/routing/core/Events/Layouts/Agenda.vue";
import VueMultiselect from 'vue-multiselect';
import $ from "jquery";

export default {
  name: "EventDetails",
  components: {
    Headline,
    Attachments,
    Editor2,
    VueDatePicker,
    VueMultiselect,
    Subevents,
    Registrations,
    Checkins,
    Agenda
  },
  props: {
    inPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "eventActive", "getConfigs"])
  },
  data() {
    return {
      event: {
        state: 1,
        waiting: 0,
        use_payment: 0,
        checkin_state: "open",
        show_suffix: 0,
        required_suffix: 0,
        show_company: 0,
        required_company: 0,
        show_firstname: 1,
        required_firstname: 1,
        show_lastname: 1,
        required_lastname: 1,
        show_birthday: 0,
        required_birthday: 0,
        required_email: 1,
        show_street: 1,
        required_street: 1,
        show_zip: 1,
        required_zip: 1,
        show_city: 1,
        required_city: 1,
        show_country: 0,
        required_country: 0,
        show_phone: 0,
        required_phone: 0,
        show_mobile: 0,
        required_mobile: 0,
        show_message: 0,
        required_message: 0,
        show_payment: 0,
        storno_refund: "yes",
        storno_refund2: "no",
        storno_refund3: "no",
        custom_settings: {},
        multi: {
          prices: [],
          options: []
        },
        priceField: "simple"
      },
      pricing: [
        {name: "simple", desc: 'Einfache Preise'},
        {name: "extended", desc: 'Mehrfach-Tickets'}
      ],
      options: {
        required: [{name: "Pflichtfeld", value: '1'}, {name: "Optional", value: '0'}],
        mode: [{name: "Radio", value: '1'}, {name: "Multiselect", value: '2'}]
      },
      isNew: true,
      eventTabs: [
        {name: 'details', label: 'Details', icon: 'fa-info'},
        {name: 'storno', label: 'Stornoregelungen', icon: 'fa-strikethrough'},
        {name: 'attachments', label: 'Anhänge', icon: 'fa-paperclip'},
        {name: 'settings', label: 'Einstellungen', icon: 'fa-cog'},
        {name: 'required', label: 'Pflichtfelder', icon: 'fa-check-square'},
        {name: 'subevents', label: 'Unterveranstaltungen', icon: 'fa-calendar'},
        {name: 'registrations', label: 'Anmeldungen', icon: 'fa-user'},
        {name: 'checkins', label: 'Checkins', icon: 'fa-clipboard-list-check'},
        {name: 'agenda', label: 'Agenda', icon: 'fa-calendar-star'},
      ],
      activeSubtab: 'details',
    };
  },
  methods: {
    updateEvent() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.event.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "event/" + VueInner.event.id, VueInner.event, {headers}).then(() => {
          this.$store.dispatch('eventsFilter');
          VueInner.$store.commit('closeEvent');
          // dispatch toastSuccess
          this.$store.dispatch('toastSuccess', 'Event wurde erfolgreich gespeichert');
          // Load Products
          // VueInner.$store.dispatch('draftsFilter');
          // this.$router.push({name: 'events'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "event", VueInner.event, {headers}).then(() => {
          VueInner.$store.commit('closeEvent');
          this.$store.commit('closeModal', 'event');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Event wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('eventsFilter');
          this.$router.push({name: 'events'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteEvent() {
      let VueInner = this;
      this.$confirm('Soll die Vorlage wirklich gelöscht werden?', 'Vorlage löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "event/" + VueInner.event.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeEvent');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Event wurde erfolgreich gelöscht');
          // Load Products
          VueInner.$store.dispatch('eventsFilter');
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadEvent() {
      if (this.$route.params.id) {
        this.$store.dispatch("setActiveEvent", this.$route.params.id).then((r) => {
          this.event = this.$store.state.activeItems.event;
          this.isNew = false;
          this.scrollToTop();
        })
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    setActiveSubtabFromRoute() {
      const {tab} = this.$route.params;
      if (tab && this.isValidTab(tab)) {
        this.activeSubtab = tab;
      } else {
        this.activeSubtab = 'details';
      }
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },

    changeTab(tabName) {
      this.activeSubtab = tabName;
      if (!this.inPopup) {
        this.$router.push({name: 'EventTabs', params: {id: this.$route.params.id, tab: tabName}});
      }
    },
  },
  created() {
    this.loadEvent();
    window.addEventListener("triggerReloadFiles", this.loadEvent);
  },
  watch: {
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.loadEvent();
      }
    }
  }
};
</script>
<style scoped>
h6.inline {
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
.alert-info.hint {
  background: #eee;
  font-size: 11px;
  font-style: italic;
  border: none !important;
}
.form-group.stornoRule .alert {
  min-height: 70px;
}

i.fa-duotone.fa-chevron-double-right.fa-xl {
  position: relative;
  top: 6px;
}
</style>
