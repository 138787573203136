<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body activities">
        <div class="row">
          <div class="col-12 col-lg-6">
             <VudeDatePicker placeholder="Von" v-model="date_from" :vertical="true" model-type="yyyy-MM-dd"  format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter()"  />
              </div>
          <div class="col-12 col-lg-6">
              <VudeDatePicker v-model="date_to" placeholder="bis" :vertical="true" model-type="yyyy-MM-dd"   format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter()"  />

            </div>
          </div>

        <perfect-scrollbar style="max-height: 70vh;">
          <div class="activityWrap" v-if="activities.length">
            <div class="row mb-3">
              <div class="col-2">
                <p class="text-xs text-secondary fw-bold mb-0">Mitarbeiter</p>
              </div>
              <div class="col-4">
                <p class="text-xs text-secondary fw-bold mb-0">Zeit</p>
              </div>
              <div class="col-2">
                <p class="text-xs text-secondary fw-bold mb-0">Typ</p>
              </div>
              <div class="col-4">
                <p class="text-xs text-secondary fw-bold mb-0">Ereignis</p>
              </div>
            </div>
            <div class="activity-item mb-3" v-for="activity in activities" v-bind:key="activity.id">
              <div class="row align-items-center h-100">
                <div class="col-2 mx-auto">
                  <img :src="$filters.employeeIcon(getEmployees, activity.u_id, 'u_id')" class="sketchAvatar">
                </div>
                <div class="col-4">
                  <p class="text-xs text-secondary  mb-0">{{ $filters.formatDateTime(activity.a_time) }}</p>
                </div>
                <div class="col-2">
                  <span class="badge badge-dark ">{{ activity.a_type }}</span>
                </div>
                <div class="col-4">
                  <p class="text-xs text-secondary  mb-0">{{ activity.a_description }}</p>
                </div>
              </div>
            </div>
          </div>
          <p v-if="!activities.length">Bitte wählen Sie ein Datum aus.</p>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<style>
.img-employee {
  width: 50px;
}
</style>
<script>
import { ref } from 'vue';
import axios from 'axios';
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]

export default {
  name: 'ActivitiesOverview',
  components: {VueDatePicker},
  computed: {
    ...mapGetters(["getEmployees"]),
  },
  methods: {
    /*
    setAbonnement(abonnement) {

      this.activeProduct = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});

    },
    */

    filter() {
      let VueInner = this;
      this.filters = '&date_from=' + VueInner.date_from + '&date_to=' + VueInner.date_to;
      this.getActivities();
    },
    getActivities() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "activity/search?limit=100" + this.filters, {headers}).then((r) => {
        this.activities = r.data.result.data;
        console.log(this.activities);
        this.loading = false;
      });
    }
  },
  data() {
    return {
      activities: [],
      date_from: '',
      date_to: '',
      filters: null,
    };
  },
  created() {
    this.a = this.activities;
    /*
    let Vueinner = this;
    this.loading = true;
    const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
    */
    //this.getActivities();
  },
}
</script>
