import {useToast} from "vue-toastification";
import i18n from "@/lang";
import store from "@/store";
import router from "@/router";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";
import {appInstance} from "@/main";
import axios from "axios";
let logger;
export const state = {
  version: '0.4.0',
  development: process.env.NODE_ENV !== 'production',
  dbVersion: 3,
  dbName: 'sketchManagerAdmin',
  loggedInState: false,
  loadTitle: 'Lade Konfiguration',
  configLoader: {
    loadText: 'Bitte warten...',
    loadPercent: 0,
  },
  menu: null,
  publishNewVersion: false,
  // --------------------------------------------------------------

  // TODO- implement action getApiUrl() - from time2023.git
  //  apiUrl: "https://api.bdfl.de/",
  apiPrivate: '42Aj4EUnVYfqZd8F8MHtBjve549pV6GdwVUu4qZJLjtvfRTApv77jMfm9CZ7zTUE',
  // apiUrl: "https://api-demo.sketch-connect.de/",
  apiUrl: "https://hfv-dev-api.sketch-manager.de/",
  // apiUrl: "https://api-dev.dfb-trainerbewerbung.de/",
  // apiUrl: "http://api.holz-kraft.com/",
 //  apiUrl: "https://api.sketch.media/",
  // apiUrl: "https://api.dfb-trainerbewerbung.de/",
  // apiUrl: "https://api.sketch.media/",
  // apiUrl: "https://api.bdfl.de/",
  // apiUrl: "https://api-time.schiffe-schnelltest.de/"
  // apiUrl: "https://api.via-nova-ostbayern.de/"

  configs: {},
  employees: {},
}

export const mutations = {
  setConfig(state, payload) {
    state.configs[payload.key] = payload.data;
  },
  createStorage(state) {
    IndexedDbFunctions.addObject(state.dbName, 1, "core", {id: state.dbName, version: state.dbVersion});
  },
  saveJson(state) {
    IndexedDbFunctions.backup(state.dbName, state);
  },
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  sidebarType(state, payload) {
    state.sidebarType = payload;
  },

  async setConfigLoader(state, payload) {
    state.configLoader = payload;
  },
  toggleSidebar(state) {
    state.sidebar = !state.sidebar;
  },
  handleApiResponse({commit}, shortResponse) {
    if (!shortResponse || !shortResponse.data || !shortResponse.data.todo) {
      return false;
    }
    const toast = useToast();
    let testMessage = shortResponse.data.todo.content;
    switch (shortResponse.data.todo.method) {
      case "showMessage":


       if (!i18n.de.toast.error[testMessage]) {
         toast.error(testMessage);
       } else {
         toast.error(i18n.de.toast.error[testMessage]);
       }
        break;
      case "logout":
        if (!i18n.de.toast.error[testMessage]) {
          toast.error(testMessage);
        } else {
          toast.error(i18n.de.toast.error[testMessage]);
        }
        store.commit("logout");
        break;
      default:
        alert("default in handleApiResponse");
    }
  },
  handleApiError(state, customMessage) {
    if(!state){ return false;}
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (customMessage === undefined) {
      state.feedback.message = "Leider hat diese Aktion nicht geklappt. Versuche es erneut!";
    } else {
      state.feedback.message = customMessage;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showAllDoneMessage(state, message) {
    state.feedback.type = "message";
    state.feedback.render = true;
    state.feedback.messageClass = "message";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showErrorMessage(state, message) {
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  changeLoggedIn(state, todo) { // or changeLoggedIn(state)
    let checkSwitch = state.loggedInState;
    if (todo === "toNull") {
      state.loggedInState = null;
      localStorage.removeItem("sketchManagerToken");
    } else if (todo === true) {
      state.loggedInState = true;
    } else {
      state.loggedInState = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === true) {
      // router.push("/");
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === false) {
      router.push("/login");
    }
  },
  setToken(state, hash) { // or changeLoggedIn(state)
    state.token = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    localStorage.setItem("sketchManagerToken", hash);
  },
  setAcl(state, acl) { // or changeLoggedIn(state)
    state.acl = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setScope(state, acl) { // or changeLoggedIn(state)
    state.scope = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setUser(state, user) { // or changeLoggedIn(state)
    state.user = user; // use with this.$store.commit("changeLoggedIn")
  },
  logout(state, redirect) {
    state.loggedInState = null;
    state.scope = {scope: null, write: false, loggedOut: true};
    state.acl = null;
    localStorage.removeItem("sketchManagerToken");
    if (redirect === undefined) {
      router.push("/signin");
    }
  },
  setFirstTimeLogin(state) {
    state.firstTimeLogin = false;
    localStorage.setItem("firstTimeLogin", "done");
  },
  forceSWupdate(state) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      })
    }
    if (localStorage.getItem("latest_cache_version") !== null && localStorage.getItem("latest_cache_version") !== 'null') {
      state.publishNewVersion = true;
    } else {
      state.publishNewVersion = false;
    }
  },
  setEmployees(state, payload) {
    state.employees = payload;
  },
}

export const actions = {
  toast({}, payload) {
    const toast = useToast();

    if (!i18n.de.toast[payload.type][payload.message]) {
      toast[payload.type](payload.message);
    } else {
      toast[payload.type](i18n.de.toast[payload.type][payload.message]);
    }

  },
  toastSuccess({dispatch}, payload) {

    dispatch("toast", {type: "success", message: payload});
  },
  toastWarning({dispatch}, payload) {
    dispatch("toast", {type: "warning", message: payload});
  },
  toastError({dispatch}, payload) {
    dispatch("toast", {type: "error", message: payload});
  },
  toastInfo({dispatch}, payload) {
    dispatch("toast", {type: "info", message: payload});
  },
  async getConfig({state}, config) {
    let key = config.type;
    if (config.subType !== undefined) {
      key = config.type + "_" + config.subType;
    }
    let returnVal;
    await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', key).then(function (storedAssets) {
      returnVal = storedAssets;
    });
    return returnVal;
  },
  toggleSidebarColor({commit}, payload) {
    commit("sidebarType", payload);
  },
  copyToClipboard({commit}, copyText) {
    const toast = useToast();
    navigator.clipboard.writeText(copyText);
    toast.success(copyText + " kopiert!");
  },
  async configsLoaderProducts({dispatch}, loadFresh) {
    await dispatch("loadProducts", loadFresh);
  },
  async configsLoaderDrafts({dispatch}, loadFresh) {
    await dispatch("loadDrafts", loadFresh);
  },
  async configsLoader({commit, dispatch, state}, loadFresh) {
    let configs = [
      {type: "employees", percent: 5, message: 'Lustige Nachrichten werden geladen'},
      {type: "customerCategories", percent: 10, message: 'Der Kaffee wird gebraut'},
      {type: "countries", percent: 15, message: 'Kekse werden gebacken'},
      {type: "customerState", percent: 17, message: 'die Gedanken werden gesammelt'},
      {type: "bankAccounts", percent: 20, message: 'Geistreiche Dialoge werden erzeugt'},
      {type: "accountingKeys", percent: 25, message: 'Zeit und Raum werden vertauscht'},
      {type: "costNumbers", percent: 30, message: 'Bits werden gezüchtet'},
      {type: "customerTax", percent: 35, message: 'Fahrstuhlmusik wird geladen'},
      {type: "taxGroups", percent: 37, message: 'Fahrstuhlmusik läuft'},
      {type: "customerAcquisition", percent: 40, message: 'Warteschlange wird gefüttert'},
      {type: "customerPrio", percent: 45, message: 'Ihre Geduld wird auf die Probe gestellt'},
      {type: "customerTypes", percent: 50, message: 'Erfolgschancen werden berechnet'},
      {type: "customerTags", percent: 50, message: 'Erfolgschancen werden berechnet'},
      {
        type: "timeConfigs",
        percent: 55,
        message: 'Bitte zerstören Sie nicht Ihren Bildschirm, es dauert nicht mehr lange'
      },
      {
        type: "categories",
        subType: 'customers',
        percent: 60,
        message: 'Die Sahnehäubchen werden auf den Kuchen gesetzt'
      },
      {type: "categories", subType: 'products', percent: 62, message: 'Das Geheimnis des Lebens wird entschlüsselt'},
      {type: "categories", subType: 'documents', percent: 64, message: 'Der Schlüssel zum Erfolg wird gesucht'},
      {type: "categories", subType: 'drafts', percent: 66, message: 'Däumchen werden gedreht'},
      {type: "categories", subType: 'events', percent: 67, message: 'Däumchen werden gedreht'},
      {type: "categories", subType: 'employees', percent: 68, message: 'Fluxkompensator wird aktiviert'},
      {type: "countries", percent: 70, message: 'Kohle wird in den Server geschaufelt'},
      {type: "customerTitleConfigs", percent: 75, message: 'Der Downloader wird heruntergeladen'},
      {type: "multilang", percent: 80, message: 'Die AGB werden gelesen'},
      {type: "params", subType: 'values', percent: 85, message: 'Immer noch schneller als ein Windows-Update'},
      {type: "invoiceTypes", percent: 90, message: 'fast fertig... Schlussspurt'},
      {type: "issue", subType: 'states', percent: 94, message: 'fast fertig... kurz vor Ziel'},
      {type: "issue", subType:'trackers', percent: 95, message: 'fast fertig... Ende in Sicht'},
      {type: "issue", subType: 'prios', percent: 97, message: 'fast fertig... noch 3%!'},
      {type: "issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'},
    ];
    let a;
    if (loadFresh) {
      for (a = 0; a < configs.length; a++) {
        await dispatch("loadConfig", configs[a]);
      }
      await commit("setConfigLoader", {loadPercent: 99});
      await dispatch("configsLoaderProducts", true);
      await commit("setConfigLoader", {loadPercent: 100});
      await dispatch("configsLoaderDrafts", true);
      await commit("setConfigLoader", {loadPercent: 0});
    } else {
      // load from indexedDB
      let type;
      for (a = 0; a < configs.length; a++) {
        type = configs[a].type;
        if (configs[a].subType !== undefined) {
          type = type + "_" + configs[a].subType;
        }
        await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', type).then(async (r) => {
          commit("setConfig", {key: type, data: r});
        });
      }
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'products').then(async (r) => {
        commit("setProducts", r);
      });
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'drafts').then(async (r) => {

      });
    }
    await dispatch("getPlugins");
    await dispatch("getEmployees");
  },
  login({commit, getters, dispatch}, payload) {
    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (!navigator.onLine) {
      commit("showErrorMessage", "Diese Funktion ist offline nicht verfügbar");
    } else {
      commit("changeLoggedIn", "toNull");
      axios.post(getters.getApiUrl + "login", payload).then(function (response) {
        let shortResponse = response.data.result;
        if (shortResponse.success === true) {
          commit("setToken", shortResponse.hash);
          const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
          axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
            commit("setMenu", r.data.result.data);
            commit("setAcl", shortResponse.acl);
            commit("setUser", shortResponse.user);
            commit("setScope", shortResponse.scope);
            commit('changeLoggedIn', true);
            if (!shortResponse.user._isEmployee) {
              dispatch('toastError', 'Sie haben keinen Zugriff auf das CRM');
            } else {
              logger.debug("load config");
              await dispatch("configsLoader", true);
              await router.push({name: "Dashboard"});
            }
          });
        } else {
          dispatch("handleApiResponse", response.response);
        }
      }).catch(function (error) {
        dispatch("handleApiResponse", error.response);
      });
    }
  },
  async checkToken({commit, getters, dispatch}) {
    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (navigator.isOnline === undefined || navigator.isOnline) {
      let token = getters.getToken;
      if (token) {
        await axios.post(getters.getApiUrl + "checkToken", {token: token}).then(async function (response) {
          let shortResponse = response.data.result;
          if (shortResponse.success === true) {
            await commit("setToken", shortResponse.hash);
            await commit("setAcl", shortResponse.acl);
            await commit("setScope", shortResponse.user.scope);
            await commit('changeLoggedIn', true);
            await commit("setUser", shortResponse.user);
            if (shortResponse.user._isEmployee) {
              const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
              axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
                await commit("setMenu", r.data.result.data);
                await dispatch("configsLoader", false);
                return shortResponse.user.scope;
              });
            } else {
              await commit("logout");
            }
          } else {
            commit("handleApiResponse", response);
          }
        }).catch(function (error) {
          commit("handleApiError", error);
        });
      }
    }
  },
  logout({commit}) {
    commit("logout");
  },
  handleApiError({commit}, error) {
    commit("handleApiError", error);
  },
  handleApiResponse({commit}, response) {
    commit("handleApiResponse", response);
  },
  async getPlugins({commit, getters}) {
    const headers = getters.getAuthorization;
    axios.get(getters.getApiUrl + "plugins", {headers}).then(function (response) {
      commit("setPlugins", response.data);
    }).catch(function (e) {
    });
  },
}

export const getters = {
  getConfigs: state => {
    return state.configs;
  },
  getToken() {
    return localStorage.getItem("sketchManagerToken");
  },
  getPlugins: state => {
    return state.plugins;
  },
  getEmployees: state => {
    return state.employees;
  },
  getUser: state => {
    return state.user;
  },
  checkLogin: state => {
    return state.loggedInState// use with this.$store.getters.checkLogin
  },
  getApiUrl: state => {
    return state.apiUrl;
  },
  getAuthorization() {
    return {"crmAuth": localStorage.getItem("sketchManagerToken")};
  },
  getMenu: state => {
    return state.menu;
  }
}
