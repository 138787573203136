<template>

  <div class="container-fluid accountingWrapper">
<Loading :loading="loading" />
    <div class="row">
      <div class="col-lg-12">
        <div class="bgCard mb-4" style="padding: 12px; border-radius: 16px; background: #fff;">
          <ul class="tabNav">
            <li @click="infoLine = 'konten'" :class="(infoLine === 'konten') ? 'active' : ''">Buchungskonten</li>
            <li @click="infoLine = 'primanota'" :class="(infoLine === 'primanota') ? 'active' : ''">Primanota</li>
            <li @click="infoLine = 'konto'" :class="(infoLine === 'konto') ? 'active' : ''">Kontoblatt</li>
            <li @click="infoLine = 'oposList'" :class="(infoLine === 'oposList') ? 'active' : ''">OPOS-Liste</li>
            <li><button class="btn btn-outline-primary btn-sm" @click="pdfGuv()" >GuV</button></li>

          </ul>

          <!--        <button class="btn btn-primary" @click="pdfSusa()" >SuSa</button>-->
        </div>
      </div>
    </div>
    <div class="row" v-if="infoLine === 'oposList'">
      <div class="col-lg-12">
        <div class="card p-4">
            <oposList />
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-9">



        <div class="row">
          <div class="col-lg-12">

            <div class="row mb-4" v-if="infoLine === 'konten'">
              <div class="col-lg-6">


                <div class="card p-4" style="height: 450px">
                  <div class="hint" v-if="!accountData.left">Aufwandskonto wählen</div>
                  <AccountData :accountData='accountData.left'/>
                </div>
              </div>

              <div class="col-lg-6">


                <div class="card p-4" style="height: 450px">
                  <div class="hint" v-if="!accountData.right">Konto wählen</div>
                  <Loading :loading="loadingAW" type="inner" />
                  <AccountData :accountData='accountData.right'/>
                </div>
              </div>

            </div>

            <div class="card infoLine p-4" v-if="infoLine === 'konto'"  >

	            <div class="input-group">
		            <div class="form-floating mb-3 w-100">

			            <input class="form-control" placeholder="Konto wählen" @keyup="checkAccount('blatt', searchKonto)" @keyup.enter="loadAccountWindow('blatt', searchKonto)" v-model="searchKonto">
                </div></div>
              <AccountData :isKonto="true" v-if="accountData.center" :accountData='accountData.center'/>

            </div>

            <div v-if="infoLine === 'primanota'" class="card p-4" style="height: 450px">
              <perfect-scrollbar style="height: 100%;">
                <div class="table-responsive p-0">
                  <h5 class="text-center">Primanota</h5>
                  <table class="accountingDataTable table align-items-center mb-0">
                    <thead>
                    <tr>
                      <th>Aktionen</th>
                      <th>Laufdnr.</th>
                      <th>Belegdat.</th>
                      <th>Belegnr.</th>
                      <th>Buchungstext</th>
                      <th class="align-right">Betrag</th>
                      <th class="text-center">BU</th>
                      <th class="align-right">Aufwand</th>
                      <th class="align-right">Konto</th>
                      <th class="text-center">Editieren</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="acc in accountingData" :key="acc.id">
                      <td>
                        <div class="btn-group">
                        <button @click="copyBooking(acc)" style="margin-bottom: 0; vertical-align: middle" title="Eintrag kopieren" class="btn btn-outline-primary btn-xs   btn-outline-success  "><i class="fa-duotone fa-clipboard"></i></button>
                        <template v-if="!acc.exported">
                          <button @click="editBooking(acc)" style="margin-bottom: 0; vertical-align: middle" title="Editieren" class="btn  btn-xs  btn-outline-warning"><i class="fa-duotone fa-pencil"></i></button>

                        <button @click="removeBooking(acc.id)" style="margin-bottom: 0; vertical-align: middle" title="Löschen" class="btn btn-xs   btn-outline-danger"><i class="fa-duotone fa-close"></i></button>
                        </template></div>
                      </td>
                      <td>{{ acc.id }}</td>
                      <td>{{ $filters.formatDate(acc.datum) }}</td>

                      <td>{{ acc.beleg1 }} <template v-if="acc.beleg2"><br><span class="hint">{{acc.beleg2}}</span></template></td>
                      <td>{{ acc.text }}</td>
                      <td class="align-right">{{ $filters.priceEuro(acc.betrag) }}</td>
                      <td class="text-center">{{ acc.bu }}</td>
                      <td class="align-right">{{ acc.aufwand }}</td>
                      <td class="align-right"> {{ acc.konto }} {{ acc.sollhaben }}</td>
                      <td class="text-center">
                        <template v-if="!acc.exported">
                        </template>

                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">

        <div class="card">
          <div class="card-body stapel" v-if="activeTab === 'aufwand' || infoLine === 'konto'" >


            <div class="w-100">
                <h6>Konten-Filter</h6>

                    <perfect-scrollbar style="max-height: 250px;">
                      <table class="accountingKeys">
                        <thead>
                        <tr>
                          <th>Schlüssel</th>
                          <th>Bezeichnung</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(acc, selector) in filteredAccounts" :key="acc.id" @click="enterAccount(activeTyper, selector)" style="cursor: pointer">
                          <td> {{ acc.key }}</td>
                          <td>{{ acc.title }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </perfect-scrollbar>

              </div>



          </div>
          <div class="card-body stapel" v-if="activeTab === 'stapel'">


            <div class="w-100">


                <h6>Aktuelle Buchungsstapel</h6>

                <perfect-scrollbar v-if="packs" style="max-height: 250px;">
                <table class="packs">
                  <thead>
                  <tr>
                    <th>Zeitraum</th>
                    <th>Titel</th>
                    <th>Zeilen</th>
                    <th>Aktionen</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr  :class="(parseInt(activePack) === parseInt(pack.id)) ? 'bg-grey2' : 'bg-grey'" v-for="pack in packs" :key="pack.id">
                    <td>{{ pack.month }}/{{ pack.year }}</td>
                    <td>{{pack.title}}</td>
                    <td>{{pack._bookingCount}}</td>
                    <td>
                      <div class="btn-group">
                      <button @click="activePack = pack.id" class="btn btn-outline-success btn-xs" title="Stapel aktivieren">
                      <!-- icon checkmark -->
                      <i class="fa-duotone fa-check"></i>
                    </button>
                      <button @click="exportPack(pack.id)" class="btn btn-outline-danger btn-xs" title="Stapel schließen & Exportieren">
                        <!-- icon disk or save -->
                        <i class="fa-duotone fa-floppy-disk"></i>
                      </button>
                      <button @click="openPack(pack.id)" class="btn btn-outline-warning btn-xs" title="Primanota öffnen">
                        <!-- icon disk or save -->
                        <i class="fa-duotone fa-eye"></i>
                      </button></div>
                    </td>
                  </tr>
                  </tbody>
                </table>


                <div class="newPack mt-4">
                  <p style="font-size: 12px">Neuen Stapel erstellen (Titel, Monat, Jahr)</p>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <div class="form-floating mb-3 w-100">

                          <input class="form-control" id="newpack_title" type="text" v-model="newPack.title">
                          <label for="newpack_title">Titel</label>

                        </div>

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-floating mb-3">
                        <input class="form-control" id="newpack_month" type="number" min="1" max="12" step="1"
                               v-model="newPack.month">
                        <label for="newpack_month">Monat</label>

                      </div>

                    </div>
                    <div class="col-6">
                      <div class="form-floating mb-3">
                        <input class="form-control" id="newpack_year" type="number" min="1" max="12" step="1"
                               v-model="newPack.year">
                        <label for="newpack_year">Jahr</label>

                      </div>
                    </div>
                    <div class="col-12">
                      <button @click="createPack()" class="btn btn-success">Erstellen</button>
                    </div>
                  </div>


                </div>
                </perfect-scrollbar>
              </div>



          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="infoLine !== 'oposList'">

      <div class="col-lg-12">
        <div class="card mb-4" style="border: 0;">
          <div class="card-body accountingTab"  @keydown="sollhabenselector($event); checkComplete()" >
            <div class="row">
              <div class="col-12">
                <div class="splits" v-if="splitBookings.length > 0">
                  <p class="hint">Summe: {{ $filters.priceEuro(calcedSplits.c) }} H: {{ $filters.priceEuro(calcedSplits.h) }} S: {{ $filters.priceEuro(calcedSplits.s) }}</p>
                  <perfect-scrollbar>

                  <div class="split hint row" style="color:#999" v-for="(split,index) in splitBookings" :key="index">
                    <div class="col-12 firstrow">
                    <div class="form-floating form-beleg1">{{split.beleg1}}</div>
                    <div class="form-floating form-datum">{{split.datum}}</div>
                    <div class="form-floating form-betrag">{{split.betrag}}</div>
                    <div class="form-floating form-sollhaben">{{split.sollhaben}}</div>
                    <div class="form-floating form-aufwand">{{split.aufwand}}</div>
                    <div class="form-floating form-konto">{{split.konto}}</div>
                    <div class="form-floating form-bu"><template v-if="split.bu">{{split.bu}}</template><template v-else>&nbsp;</template></div>
                    <div class="form-floating form-butext">{{split.text}}</div>
                    <div class="form-floating form-beleg1">{{split.beleg2}}</div>
                    <div class="form-floating form-btns">
                      <span class="delBtn" style="cursor:pointer;" @click="removeSplit(index)">X</span>
                    </div>
                    </div>
                  </div>
                  </perfect-scrollbar>
                </div>
                <p :class="'bg-'+feedbackType" class="text-white p-4" v-if="feedbackMessage">
                  {{ feedbackMessage }}</p>
                <div class="noPack" v-if="!activePack">
                  <p>Es ist kein Stapel ausgewählt.</p>
                </div>
                <div class="bookingMask" v-else>
                  <div class="row">
                    <div class="col-12 firstrow">
                      <div class="form-floating form-beleg1 mb-3">
                        <input class="form-control" ref="beleg1" id="acc_beleg1" type="text"  v-model="accounting.beleg1">
                        <label for="acc_beleg1">Beleg 1</label>
                      </div>

                      <div class="form-floating form-datum mb-3">
                        <input class="form-control" id="acc_day" @blur="formatDay()" type="text"
                               v-model="accounting.datum">
                        <label for="acc_day">Datum</label>
                      </div>

                      <div class="form-floating form-betrag mb-3">
                        <input class="form-control" id="acc_betrag" @blur="formatDecimal()" type="text"
                               v-model="accounting.betrag">
                        <label for="acc_betrag">Betrag</label>
                      </div>

                      <div class="form-floating form-sollhaben mb-3">
                        <select class="form-control" id="acc_sollhaben" v-model="accounting.sollhaben">
                          <option :selected="(!accounting.sollhaben)" value="S">S</option>
                          <option value="H">H</option>
                        </select>
                        <label for="acc_sollhaben">S/H</label>
                      </div>

                      <div class="form-floating form-aufwand mb-3">
                        <input class="form-control" id="acc_aufwand" @keyup.enter="enterAccount('aufwand')"
                               @blur="loadAccountWindow('aufwand', accounting.aufwand)"
                               ref="aufwand"
                               @keyup="checkAccount('aufwand', accounting.aufwand)" type="text"
                               v-model="accounting.aufwand">
                        <label for="acc_aufwand">Aufwand</label>
                      </div>

                      <div class="form-floating form-konto mb-3">
                        <input class="form-control" id="acc_konto" @keyup.enter="enterAccount('konto')"
                               @blur="loadAccountWindow('konto', accounting.konto)"
                               @keyup="checkAccount('konto', accounting.konto)" type="text"
                               v-model="accounting.konto">
                        <label for="acc_konto">Konto</label>
                      </div>
                      <div class="form-floating form-bu mb-3">
                        <input class="form-control" id="acc_bu" type="text" v-model="accounting.bu" ref="accRefBu"
                               @blur="checkBu()">
                        <label for="acc_bu">BU</label>
                      </div>

                      <div class="form-floating form-butext mb-3">
                        <input class="form-control" id="acc_text"  type="text" v-model="accounting.text">
                        <label for="acc_text">Buchungstext</label>
                      </div>
                      <div class="form-floating form-beleg1 mb-3">
                        <input class="form-control" id="acc_beleg2" @blur="getKontoBlatt(accounting.beleg2)" type="text" v-model="accounting.beleg2">
                        <label for="acc_beleg2">Beleg 2</label>
                      </div>
                      <div class="form-floating form-btns">
                        <p class="mt-2" v-if="completeChecker">
                          <button @click="sendAccountingData()" class="btn btn-outline-success btn-small me-2">Absenden!</button>
                          <button @click="splitBooking()" class="btn btn-outline-warning btn btn-small me-2">Splitten</button>
                          <button @click="splitBookings = []" v-if="splitBookings.length > 0" class="btn btn-outline-danger btn-small me-2">Verwerfen</button>
                        </p>
                        <p v-else class="mt-2">
                          <button  class="btn btn-disabled btn-outline-success btn-small me-2" disabled>Absenden!</button>
                          <button  class="btn btn-disabled btn-outline-warning btn-small me-2" disabled>Splitten</button>
                          <button  v-if="splitBookings.length > 0" class="btn btn-disabled btn-small me-2 btn-outline-danger" disabled>Verwerfen</button>

                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">


                    <div class="col-6 hint " v-if="accountData.left && accountData.left.sorted">
                      Aufwand: {{ accountData.left.sorted.account.key }} - {{ accountData.left.sorted.account.title }}
                    </div>
                    <div class="col-6 hint" v-else>Aufwand: wird angelegt!</div>

                    <div class="col-6 hint" v-if="accountData.right && accountData.right.sorted">
                      Konto: {{ accountData.right.sorted.account.key }} - {{ accountData.right.sorted.account.title }}
                    </div>
                    <div class="col-6 hint" v-else>Konto: wird angelegt!</div>



                  </div>


                  <div class="row secondRow">

                    <div class="col-1">
                      <div class="form-floating mb-3">
                        <input class="form-control" id="acc_kost1" type="text" v-model="accounting.kost1">
                        <label for="acc_kost1">Kost 1</label>
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-floating mb-3">
                        <input class="form-control" id="acc_kost2" type="text" v-model="accounting.kost2">
                        <label for="acc_kost2">Kost 2</label>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="form-floating mb-3">
                        <input class="form-control" id="acc_notizen" type="text" v-model="accounting.notizen">
                        <label for="acc_notizen">Notizen</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import axios from "axios";
import AccountData from "./AccountData";
import OposList from "./OposList";
import Loading from "@/components/Loading"

export default {

  name: 'Accounting',
  components: {
    AccountData,
    OposList,
    Loading
  },
  data() {


    return {
      infoLine: 'konten',
      packs: [],
      newPack: {
        title: '',
        month: '',
        year: ''
      },
	    searchKonto: '',
      activeTab: 'stapel',
      activePack: false,
      accounting: {},
      bus: [],
      feedbackType: '',
      feedbackMessage: '',
      accounts: [],
      filteredAccounts: [],
      accountData: {
        left: null,
        right: null,
          center: null,
      },
      accountingData: [],
      completeChecker: false,
      splitBookings: [],
      calcedSplits: {
        s: 0,
        h:0,
        c:0,
      },
      activeTyper: 'konto',
      loading: false,
      loadingAW: false
    }

  },

  methods: {
    removeBooking(id) {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "accounting/entry/"+id, {headers}).then(() => {

        this.getAccountingData();
        this.loadAccountWindow('konto', this.accounting.konto);
        this.loadAccountWindow('aufwand', this.accounting.aufwand);

        this.loading = false;
      });


    },
    editBooking(acc) {
      this.accounting.id = acc.id;
      this.copyBooking(acc);
    },
    copyBooking(acc) {
      this.accounting.beleg1 = acc.beleg1;
      this.accounting.kost1 = acc.kost1;
      this.accounting.beleg2 = acc.beleg2;
      this.accounting.kost2 = acc.kost2;
      this.accounting.notiz = acc.notiz;
      if (acc.bu !== '0') {
      this.accounting.bu = acc.bu;
      }
      this.accounting.text = acc.text;
      this.accounting.datum = acc.datum;
      this.accounting.betrag = acc.betrag;
      this.accounting.konto = acc.konto;
      this.accounting.aufwand = acc.aufwand;
      this.accounting.sollhaben = acc.sollhaben;
      this.formatDecimal();
      this.checkComplete();
    },
    removeSplit(ind) {
      this.splitBookings.splice(ind, 1);
      this.calculateSplit();
    },
    sollhabenselector($event) {

     if ($event.code === 'NumpadSubtract' || $event.code === 'NumpadAdd') {
$event.preventDefault();
       this.accounting.sollhaben = ($event.code === 'NumpadSubtract') ? 'S' : 'H';
       this.$refs.aufwand.focus();
     }

    },
    splitBooking() {

      // clone this accounting
      let accounting = JSON.parse(JSON.stringify(this.accounting));

      this.splitBookings.push(accounting);
      this.calculateSplit();
    },
    calculateSplit() {
      let betrag;
      let sum = {
        s: 0,
        h:0,
        c:0,
      };
      for(let i = 0; i < this.splitBookings.length; i++) {
        // replace comma with dot
        betrag = this.splitBookings[i].betrag.replace(',', '.');

        if (this.splitBookings[i].sollhaben === 'S') {
          sum.s += parseFloat(betrag);
        } else {
          sum.h += parseFloat(betrag);
        }

      }
      sum.c = sum.s - sum.h;

      this.calcedSplits = sum;
    },
    loadAccountWindow(type, value) {

      // 5 numbers format leading zero





      this.loadingAW = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let query = 'listByKey';
      // if (type === 'blatt') {
      //   query = 'listKonto'
      // }
      // else {
      if (value) {
        value = value.padStart(5, '0');
      }
        this.accounting[type] = value;
      // }

      axios.get(this.$store.state.apiUrl + "accounting/"+query+"/" + value, {headers}).then((r) => {
	      console.log("blaat?", r.data.result.data);
	      console.log("blaat2?", type);
        if (type === 'aufwand') {
          this.accountData.left = r.data.result.data;
        }
        else if (type === 'blatt') {

			  console.log("blattResult", r.data.result.data);
          this.accountData.center = r.data.result.data;
        }
        else {
          this.accountData.right = r.data.result.data;
          this.activeTab = 'stapel';
        }
        this.loadingAW = false;
      });


    },
    enterAccount(type, selector) {
if (selector === undefined) {
  selector = 0;
}
console.log("typeEnterAccount", type);
      this.accounting[type] = this.filteredAccounts[selector].key;
this.loadAccountWindow(type, this.filteredAccounts[selector].key);
      this.activeTab = 'stapel';

    },
    checkAccount(type, value) {

      if (type === 'konto' || type === 'aufwand' || type === 'blatt') {

		    this.activeTab = 'aufwand';

        this.activeTyper = type;
        if (value && value.length > 0) {
          this.filteredAccounts = this.accounts.filter(acc => (acc.key.includes(value) || acc.title.toLowerCase().includes(value.toLowerCase())));
        } else {
          this.filteredAccounts = this.accounts;
        }

      }

    },
    checkBu() {
      let current = this.accounting.bu;

      if (!current) {
        this.resetFeedback();
      } else {
        let existing = false;
        for (let i = 0; i < this.bus.length; i++) {
          if (this.bus[i].bu === current) {
            existing = true;
            break;
          }
        }
        if (!existing) {
          this.feedback("BU existiert nicht", "danger");
        } else {
          this.resetFeedback();
        }

      }
    },
    openPack(packId) {
      this.getAccountingData([{filter:'filter_pack', value:packId}], 1000, 0);
    },
    resetFeedback() {
      this.feedbackType = '';
      this.feedbackMessage = '';
    },
    feedback(message, type) {
      this.feedbackMessage = message;
      this.feedbackType = type;
    },
    exportPack(packId) {

      this.$confirm("Abschließen?", "Sie möchten die aktuellen Stapel abschließen und exportieren?", "question", {cancelButtonText: "Nein, doch nicht!", confirmButtonColor:"#21AE80"}).then(() => {

            const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "accounting/exportPack/" + packId, {},{headers}).then((r) => {
        if (r.data.result.success) {
          // force download zip file from base64
          const linkSource = 'data:text/csv;base64,' + r.data.result.data;

          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', r.data.result.filename);
          window.document.body.appendChild(a);
          a.click();

          this.activePack = null;
          this.getPacks();
          this.getAccountingData();

        } else {
          this.feedback("Export fehlgeschlagen", "danger");
        }
      });
      });
    },
    formatDecimal() {

      // set komma at last two digits


      let current = this.accounting.betrag;

      // remove all commas
      current = current.replace('.', '');
      current = current.replace(',', '');
      current = current.replace('-', '');
      current = current.replace('+', '');

      if (current.length === 1) {
        current = "00" + current;
      }
      if (current.length === 2) {
        current = "0" + current;
      }
      let lastTwo = current.slice(-2);
      let first = current.slice(0, -2);
      this.accounting.betrag = first + ',' + lastTwo;


    },

    formatDay() {

      let entry = this.accounting.datum;
      let day;
      let month;
      let date = new Date();
      if (entry.length === 1 || entry.length === 2) {
        day = entry;
        month = (date.getMonth() + 1).toString();
      } else if (entry.length === 3 || entry.length === 4) {
        // substr entry on position 3
        day = entry.substr(0, 2);
        month = entry.substr(2, 2);


      }
      if (day.length === 1) {
        day = '0' + day;
      }

      let year = date.getFullYear();

      this.$log.debug(month);
      this.$log.debug(month.length);

      if (month.length === 1) {
        month = '0' + month;
      }

      this.accounting.datum = year + '-' + month + '-' + day;

    },

    getPacks() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/packs", {headers}).then((r) => {
        this.packs = r.data.result.data;
        this.loading = false;
      });
    },

    getBUs() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/bus", {headers}).then((r) => {
        this.bus = r.data.result.data;
        this.loading = false;
      });
    },
    createPack() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "accounting/packs", this.newPack, {headers}).then((r) => {
        this.getPacks();
        this.activePack = r.data.result.data;
        this.loading = false;
      });
    },
    getAccounts() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/accounts", {headers}).then((r) => {
        this.accounts = r.data.result.data;
        this.filteredAccounts = this.accounts;
        this.loading = false;
      });
    },
    pdfGet (type) {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/pdf/"+type, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data;

        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'export_'+type+'.pdf');
        window.document.body.appendChild(a);
        a.click();
      });
    },
    pdfGuv () {
      this.pdfGet('guv');
    },
    pdfSusa () {
      this.pdfGet('susa');
    },
    getAccountingData(filters, limit, offset) {

      if (!limit) {
        limit = 20;
      }
      if (!offset) {
        offset = 0;
      }
      let filterQueue = '';
      if (filters) {
        for (let key in filters) {
          if (filters[key].value !== '') {
            filterQueue += '&'+ filters[key].filter + '=' + filters[key].value ;
          }
        }
      }
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/search?offset="+offset+"&limit="+limit+filterQueue, {headers}).then((r) => {
        this.accountingData = r.data.result.data;
        this.filteredAccounts = this.accounts;
        this.loading = false;
      });
    },
    getKontoBlatt($key) {
      this.loadAccountWindow("blatt", $key)
    },
    setMonthYear() {

      const today = new Date();
      this.newPack.month = today.getMonth() + 1;
      this.newPack.year = today.getFullYear();
    },
    checkComplete() {

        let complete = true;
        let needFul = ['datum', 'betrag', 'aufwand', 'konto', 'text'];
        for (let i = 0; i < needFul.length; i++) {
          if (!this.accounting[needFul[i]]) {
            complete = false;
            break;
          }
        }
        this.completeChecker = complete;
        if (complete) {
        this.activeTab = 'stapel';
        }
      },
    sendAccountingData() {

      if (this.splitBookings.length > 0) {


        let currentAccounting;
        let i;
        for (i in this.splitBookings) {
        currentAccounting = this.splitBookings[i];
          this.loading = true;
          const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
          let dataPE = {};

          for (let key in currentAccounting) {
            if (currentAccounting[key]) {
              dataPE[key] = currentAccounting[key];
            }
          }


          dataPE.pack_id = this.activePack;


          axios.post(this.$store.state.apiUrl + "accounting/entry", dataPE, {headers}).then(() => {

            this.getAccountingData();
            this.loadAccountWindow('konto', dataPE.konto);
            this.loadAccountWindow('aufwand', dataPE.aufwand);

            this.loading = false;
          });
        }


      } else {

        this.loading = true;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        let dataPE = {};

        for (let key in this.accounting) {
          if (this.accounting[key]) {
            dataPE[key] = this.accounting[key];
          }
        }


        dataPE.pack_id = this.activePack;


        if (dataPE.id) {
        axios.put(this.$store.state.apiUrl + "accounting/entry", dataPE, {headers}).then(() => {

          this.getAccountingData();
          this.loadAccountWindow('konto', dataPE.konto);
          this.loadAccountWindow('aufwand', dataPE.aufwand);
          dataPE.id = null;
          this.accounting.id = null;
          this.loading = false;
        });
        }
        else {

        axios.post(this.$store.state.apiUrl + "accounting/entry", dataPE, {headers}).then(() => {

          this.getAccountingData();
          this.loadAccountWindow('konto', dataPE.konto);
          this.loadAccountWindow('aufwand', dataPE.aufwand);

          this.loading = false;
        });
        }
      }

      setTimeout(() => {
        this.accounting.betrag = null;
        this.accounting.aufwand = null;
        this.accounting.text = null;
        this.accounting.bu = null;
        this.accounting.beleg2 = null;
        this.splitBookings = [];
        this.completeChecker = false;
        this.$refs.beleg1.focus();
        this.activeTab = 'stapel';
      }, 500);

    }
    },


  created() {
    this.getAccounts();
    this.getBUs();
    this.getPacks();
    this.setMonthYear();
    this.getAccountingData();

    // TODO via Param -> this ist BDFL prefill
    this.accounting.konto = '09470';
    this.loadAccountWindow('konto', this.accounting.konto)

  },
  watch: {
    activePack() {
      this.getAccountingData([{filter:'filter_pack', value:this.activePack}], 1000, 0);
    }
  },

}

</script>
<style lang="scss">
.split .firstrow {
  border-bottom: 1px solid #ccc;
  padding:6px 0;
  .form-floating {
    padding: 0 6px;
    color: #000;
    font-weight: normal;
    margin-right: 8px !important;
    &.form-btns {
      padding:0;
      width: 10% !important;
    }
  }
}
.form-floating:not(.form-control:disabled)::before {
  background: transparent;
}
.bookingMask .form-floating > .form-control, .bookingMask .form-floating > label{padding: 1rem 0.15rem;}
.bookingMask .form-floating > label{margin-left: 0;}
.accountingWrapper .ps {
  height: 300px;
}
table.packs {
  width: 100%;
  margin: 0;
  td,th {
    font-size: 12px;
    padding: 4px;
    border-bottom: 3px solid #fff;
    vertical-align: center;
    .btn {
      padding: 0px;
      width: 24px;
      height: 24px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  tr.bg-grey {
    td {
      background-color: #f5f5f5;
    }
  }
  tr.bg-grey2 {
    td {
      color:#333;
      background-color: #a5a5a5;
    }
  }
}
p.hint {
  text-align: left;
  color: #696969 !important;
  font-size: 12px;
}
.btn-mini {
  padding: 0 !important;
  height: 30px;
  border-radius: 0 !important;
  width: 30px;
}

.bgCard .btn {
  margin-bottom: 0;
  margin-right: 6px;
}
p.btnGroup {
  float: right;
  .btn {
    margin: 6px;
  }
}
.secondRow {
  display: none !important;
}

table.accountingKeys, table.accountingDataTable {
  td, th {
    font-size: 12px;
    padding: 5px 12px;
    vertical-align: top;
  }
}
.accountData table.accountingDataTable th{
  color: #333;
}
.accountData hr{
  margin-bottom: 3px;
}
.accountData p{
  margin-bottom: 0;
}
.splits .hint {
  color:#000;
  font-weight: bold;
}
.splits .ps {
  height: 170px;
}
.accountingTab {
  &.card-body {
    position: fixed;
    bottom: 24px;
    z-index: 999999999;
    width: 83%;
    border-radius: 16px;
    box-shadow: 0 0 10px #ccc;
    background: #f3f3f3;
  }
}
.bookingMask .row > * {
  padding: 0 5px;

  .form-control {
    font-size: 12px !important;
    height: 52px;
    padding-top: 23px;
    padding-bottom: 5px;
  }
}

.align-right {
  text-align: right;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  height: 12px;
  line-height: 12px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.split {
  background: #f3f5f6;
  padding: 6px;
  width: 100%;
  margin-bottom: 2px;
}

.splits {
  margin-bottom: 12px;
}

.split span {
  float: right;
  background: #fb6340;
  width: 20px;
  text-align: center;
  color: #fff;
}
.col-12.firstrow .form-floating {
  width: 5%;
  float: left;
  margin-right: 5px;
  &.form-betrag, &.form-datum {
    width: 6%;
  }
  &.form-btns {
    width: 18%;
  }
  &.form-bu {
    width: 5%;
  }
  &.form-beleg1, &.form-beleg2 {
    width: 10%;
  }

  &.form-sollhaben {
    width: 6%;
  }
  &.form-butext {
    width: 25%;
  }
}
.infoLine table {
  width: 100%;
}
button.btn-small {
  padding: 12px 10px;
  font-size: 13px;
  border-radius: 5px;
  font-weight: normal;
}
.bookingMask .hint {
  color: #333
}
button[disabled]{
  cursor: not-allowed  !important;
}
</style>




