<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("employee.timeConfigs.details.configWorkingDays")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigTimeConfigs');$store.commit('closeModal', 'ConfigTimeConfigs')">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body">
      <div class="row">
        <div class="col-4">
            <div class="form-group">
              <label>{{$t("globals.month")}}</label>
              <select   v-model="d.month" class="form-select" >
                <option value="01">{{$t("globals.january")}}</option>
                <option value="02">{{$t("globals.february")}}</option>
                <option value="03">{{$t("globals.march")}}</option>
                <option value="04">{{$t("globals.april")}}</option>
                <option value="05" >{{$t("globals.may")}}</option>
                <option value="06">{{$t("globals.june")}}</option>
                <option value="07">{{$t("globals.july")}}</option>
                <option value="08">{{$t("globals.august")}}</option>
                <option value="09">{{$t("globals.september")}}</option>
                <option value="10">{{$t("globals.october")}}</option>
                <option value="11">{{$t("globals.november")}}</option>
                <option value="12">{{$t("globals.december")}}</option>
              </select>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>{{$t("globals.year")}}</label>
              <select  v-model.number="d.year" class="form-select">
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </div>

        <div class="col-4">
          <div class="form-group">
            <label>{{$t("employee.timeConfigs.details.workingDays")}}</label>
            <input class="form-control" type="number" v-model="d.workdays"  step="1">
          </div>
        </div>

      </div>
        </div>
      </div>
    </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Employee/TimeConfigs/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "TimeConfigs",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigTimeConfigs", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "configs/timeConfigs" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Draft wurde erfolgreich gespeichert');
          // Load Products
          this.$router.push({name: 'TimeConfigs'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "configs/timeConfigs", VueInner.d, {headers}).then(() => {

          this.$store.commit('closeModal', 'ConfigsTimeConfigs');
          this.$router.push({name: 'TimeConfigs'})
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gespeichert');
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteDraft() {
      let VueInner = this;
      this.$confirm('Sollen die Arbeitstage wirklich gelöscht werden?','Arbeitstage löschen', "question",  {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {

        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "configs/timeConfgis" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeConfigTimeConfigs');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Arbeitstage wurden erfolgreich gelöscht');
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d = this.getConfigTimeConfigs

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
<style scoped>
h6.inline{
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
