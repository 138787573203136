<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigCustomerType && $store.state.activeItems.ConfigCustomerType.id === draft.id) ? 'active' : ''" >
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.name }}
    </div>
    <div class="lineitem l-color" :style="'background-color:'+draft.color + '; color: #fff;'">
      {{ draft.color }}
    </div>
    <div class="lineitem l-icon">
      <i class="fa-duotone" :class="'fa-'+draft.icon" :style="'color:'+draft.color"></i>
    </div>
    <div class="lineitem l-default">
      {{ draft.ordering }}
    </div>
    <div class="lineitem l-default">
      <template v-if="draft.use_as_default==='1' ">
        <i class="fa-duotone fa-check-circle"></i>
      </template>
    </div>
    <div class="lineitem l-default">
      {{ draft.rabatt }}
    </div>


    <div class="lineitem last ml-auto">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigCustomerType && $store.state.activeItems.ConfigCustomerType.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigCustomerType');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["getEmployees"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigCustomerType'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigCustomerTypeDetails', params: {id: draft.id}})
      });


    },
  }
}
</script>
