<template>
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <template v-if="projectItem.id">
              Projekt bearbeiten
            </template>
            <template v-else>
              Neues Projekt
            </template>
          </h5>
          <button type="button" class="btn-close" @click="$store.commit('closeModal', 'project');"></button>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label for="projectTitle">Projektname</label>
            <input type="text" class="form-control" id="projectTitle" v-model="projectItem.title">
          </div>
          <div class="form-group">
            <label>Projektstatus</label>
            <select name="" id="" v-model="projectItem.state">
              <option v-for="info in states"  :value="info">{{
                  $t('project.state.' + info)
                }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Projektleiter</label>
            <select name="" id="" v-model="projectItem.employee_id">
              <option v-for="info in getEmployees"  :value="info.id">{{
                  info.e_name
                }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="projectDesc">Beschreibung</label>
            <Editor2 v-model="projectItem.description"></Editor2>
          </div>

              <div class="form-group">
                <label for="projectDesc">External Ressource</label>
                <input type="text" class="form-control" v-model="projectItem.external_ressource"
                       placeholder="URL"/>
              </div>




          <!--add Checkbox group for dev and live -->


          <div class="plugins" v-if="getPlugins.infos && getPlugins.infos.accessData">
            <div class="inlinePasswordEdit">
              <template v-if="showPassEdit === false">
                <button class="btn btn-outline-primary" @click="showPassEdit = true;"><i
                  class="fa-duotone fa-plus-circle"></i> Passwort hinzufügen
                </button>
              </template>
              <template v-else>
                <button class="btn btn-outline-primary" @click="showPassEdit = false;"><i
                  class="fa-duotone fa-close"></i>
                  Schließen
                </button>
              </template>

            </div>
            <CustomerPasswords v-if="showPassEdit === true"/>
            <div class="form-group">
              <label for="environment">Environment:</label>
              <ul class="tabNav subtabs">
                <li v-for="en in environment" :key="en" class="firstToUpper"
                    @click="setSubtab(en)"
                    :class="(activeSubtab === en) ? 'active' : ''"> {{ en }}
                </li>
              </ul>

            </div>

            <div class="repeatForm"  v-for="env in environment">
              <template v-if="activeSubtab === env">
                <div class="card">
                  <h6 class="card-header">{{ env }}</h6>

                <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>URL</label>
                    <input type="text" class="form-control" v-model="projectItem.plugins.accessData[env].url"
                           placeholder="URL"/>

                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>FTP</label>
                    <select name="" id="" v-model="projectItem.plugins.accessData[env].ftp">
                      <option v-for="info in accessData" v-if="accessData.length" :value="info.id">{{
                          info.a_title
                        }}{{ info.url }}
                      </option>
                    </select>
                  </div>

                </div>

              </div>

              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>Provider</label>
                    <select v-model="projectItem.plugins.accessData[env].providerType">
                      <option v-for="pro in providers" :value="pro">{{ pro }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Provider-Zugang</label>
                    <select name="" id="" v-model="projectItem.plugins.accessData[env].provider">
                      <option v-for="info in accessData" v-if="accessData.length" :value="info.id">{{
                          info.a_title
                        }}{{ info.url }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>PHP</label>
                    <select v-model="projectItem.plugins.accessData[env].php">
                      <option v-for="phpVersion in phpversions" :value="phpVersion.value">{{
                          phpVersion.label
                        }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="row">

                <div class="col-12">
                  <div class="form-group">
                    <label>URLs Git</label>
                    <textarea class="form-control" v-model="projectItem.plugins.accessData[env].gits"></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>URLs Cron</label>
                    <textarea class="form-control" v-model="projectItem.plugins.accessData[env].crons"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Software</label>
                    <select v-model="projectItem.plugins.accessData[env].software">
                      <option v-for="software in softwares" :value="software.label">{{ software.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Wartung</label>
                    <select v-model="projectItem.plugins.accessData[env].wartung">
                      <option value="1">Ja</option>
                      <option value="0">Nein</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="projectDesc">Beschreibung</label>
                    <Editor2 v-model="projectItem.plugins.accessData[env].description"></Editor2>
                  </div>

                </div>
              </div>
                </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col"><button type="button" class="btn btn-outline-secondary"
                                      @click="$store.commit('closeModal', 'project');"><i class="fa-duotone fa-xmark-circle"></i> Abbrechen
            </button>
            </div>
            <div class="col">
              <template v-if="projectItem.id">
                <button type="button" class="btn btn-outline-primary float-end" @click="updateProject"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
              </template>
              <template v-else>
                <button type="button" class="btn  btn-outline-primary float-end" @click="postProject"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
              </template>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerPasswords from "@/routing/core/Customers/Passwords.vue";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Edit",
  emits: ['refresh-project-list'],
  components: {Editor2, CustomerPasswords},
  data() {
    return {
      projects: [],
      projectItem: {},
      newTask: false,
      newProject: false,
      showPassEdit: false,
      activeSubtab: 'live',
      accessData: [],
      today: new Date().toISOString().slice(0, 10),
      environment: ['live', 'dev', 'api', 'admin', 'other'],
      states: ['prepare','progress','finished','deleted'],
      providers: ['hosting', 'df', 'extern'],
      phpversions: [
        {label: '8.2', value: '8.20'},
        {label: '8.1', value: '8.10'},
        {label: '8.0', value: '8.00'},
        {label: '7.4', value: '7.40'},
        {label: '7.3', value: '7.30'},
        {label: '7.2', value: '7.20'},
        {label: '7.1', value: '7.10'},
        {label: '7.0', value: '7.00'},
        {label: '5.6', value: '5.60'},
      ],
      softwares: [
        {label: 'Joomla4'}, {label: "VueJS"}, {label: "Wordpress"}, {label: "Joomla3"}
      ]
    }
  },
  mounted() {
    this.getAccessData();
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "getPlugins"])
  },
  props: {
    newProjectItem: {

    }


  },
  methods: {
    postProject() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: this.projectItem

      }
      axios.post(this.$store.state.apiUrl + "project", postContent, {headers}).then((r) => {


        this.$emit("refresh-project-list");
        this.$store.commit('closeModal', 'project');
        this.$store.dispatch("toastSuccess", "Eintrag erfolgreich gespeichert.");
      }).catch((e) => {

        this.$store.commit("handleApiResponse", e.response);
      });

    },
    getAccessData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
    updateProject() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: this.projectItem
      }
      axios.put(this.$store.state.apiUrl + "project/" + this.projectItem.id, postContent, {headers}).then((r) => {
        this.$store.commit('closeModal', 'project');
        this.$store.dispatch("toastSuccess", "Eintrag erfolgreich gespeichert.");
      });
    },
    setSubtab(subtab) {
      this.activeSubtab = subtab;
      this.$router.push({
        name: 'CustomersTabsSubtabs',
        params: {id: this.customerActive.id, tab: this.$route.params.tab, subtab: subtab}
      })
    }
  },
  created() {
    this.projectItem = this.newProjectItem;
    if (this.projectItem) {
    let a;
    if (!this.projectItem.plugins) {
      this.projectItem.plugins = {
        accessData: {}
      }
    }

    if (this.projectItem.plugins && !this.projectItem.plugins.accessData && this.getPlugins.info && this.projectItem.plugins.accessData) {
      this.projectItem.plugins.accessData = {}
    }
    for(a in this.environment) {
      if (!this.projectItem.plugins.accessData.hasOwnProperty(this.environment[a]) || this.projectItem.plugins.accessData[this.environment[a]] === null) {
      this.projectItem.plugins.accessData[this.environment[a]] = {
        id:'',
        project_id:'',
        provider: '',
        url: '',
        providerType: '',
        ftp: '',
        php: '',
        gits: '',
        crons: '',
        software: '',
        wartung: '',
        description: '',
      }
      }
    }
    }
  }

}
</script>
<style lang="scss" scoped>
select {
  width: 100%;
}

.projects-item > .card > .card-header {
  padding-bottom: 0;
}
</style>
