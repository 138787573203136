<template>
  <div class="card">
    <h6 class="card-header">{{activeSubtab}} {{$t("documents.from")}} {{customerActive.c_company}}
      <div class="dropdown d-inline-block ml-auto">
        <button class="btn btn-sm ms-3 dropdown-toggle btn-outline-primary" id="customerDocActions" type="button" :class="(showDocDropdown) ? 'active' : ''"
                data-toggle="dropdown" aria-expanded="false" @click="toggleDropdown()">
          <i class="fa-duotone fa-file"></i> {{$t("documents.createDocument")}}
        </button>
        <ul class="dropdown-menu" aria-labelledby="customerDocActions" v-if="showDocDropdown">
          <li>
            <button class="dropdown-item" @click='$store.commit("openModal", "document");'><i class="fa-duotone fa-file-contract"></i>
              {{$t("globals.order")}}
            </button>
          </li>
          <li>
            <button class="dropdown-item" @click="showContract = true"><i class="fa-duotone fa-file-signature"></i> {{$t("globals.contract")}}
            </button>
          </li>
          <li>
            <button class="dropdown-item" @click="showPdf = true"><i class="fa-duotone fa-file-pdf"></i> {{$t("documents.emailPDF")}}
            </button>
          </li>
        </ul>
      </div>
    </h6>
    <div class="card-body">
      <ul class="tabNav subtabs">
        <li v-for="cat in categories" :key="cat.id"
            @click="$router.push({name:'CustomersTabsSubtabs', params:{id:customerActive.id,tab:$route.params.tab, subtab: cat.title}})"
            :class="(activeSubtab === cat.title) ? 'active' : ''">{{ cat.title }}
        </li>
      </ul>
      <p v-if="documents.length === 0">{{$t("documents.noDocumentsFound")}}</p>
      <template v-else>
        <div class="documentWrap">
          <perfect-scrollbar style="max-height: 440px;">
            <div class="document" v-for="document in documents">
              <div class="d-flex w-100">
                <div class="lineitem l-number" @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})">
                  {{ document.doc_number }}
                </div>
                <div class="lineitem l-title l-doctitle" @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})">
                  {{ document.info_field_1 }}
                </div>
                <div class="lineitem l-date">{{ $filters.formatDate(document.doc_d_date) }}</div>
                <div class="lineitem l-price ">{{ $filters.priceEuro(document.complete_brutto) }}</div>
                <div class="lineitem last ml-auto" @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})">
                  <i class="fa-duotone fa-chevrons-up" ></i>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </template>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <Modal v-if="showPdf">
        <div class="modal fade show" id="modalPDF" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-body bg-gray-100">
                <div class="row">
                  <div class="col-4">
                    <button type="button" class="btn btn-outline-primary w-100"
                            :class="(activeMethod === 'mail') ? 'btn-primary' : ''"
                            @click="activeMethod = 'mail'; ">{{$t("globals.email")}}
                    </button>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-outline-primary w-100"
                            :class="(activeMethod === 'attachment') ? 'btn-primary' : ''"
                            @click="activeMethod = 'attachment'">{{$t("documents.templateAttachment")}}
                    </button>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-outline-primary w-100"
                            :class="(activeMethod === 'pdf') ? 'btn-primary' : ''" @click="activeMethod = 'pdf'; ">
                      {{$t("documents.onlyCreatePDF")}}
                    </button>
                  </div>
                </div>
                <hr>
                <div class="row mt-5">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100" @click="withTemplate = true"
                                :class="(withTemplate) ? 'btn-primary' : ''">
                          {{$t("documents.withEmailTemplate")}}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100" @click="withTemplate = false"
                                :class="(withTemplate) ? '' : 'btn-primary'">
                          {{$t("documents.withoutEmailTemplate")}}
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="activeMethod !== 'mail'">
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100"
                                @click="pdfOptions.header = 'with'"
                                :class="(pdfOptions.header === 'with') ? 'btn-primary' : ''">
                          {{$t("documents.withPDFHeader")}}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100"
                                @click="pdfOptions.header = 'without'"
                                :class="(pdfOptions.header === 'with') ? '' : 'btn-primary'">
                          {{$t("documents.withoutPDFHeader")}}
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="activeMethod !== 'mail'">
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = true"
                                :class="(pdfOptions.bg === true) ? 'btn-primary' : ''">
                          {{$t("documents.withPDFBackground")}}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = false"
                                :class="(pdfOptions.bg === true) ? '' : 'btn-primary'">
                          {{$t("documents.withoutPDFBackground")}}
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <select class="form-control" @change="setDraft()" v-model="activeDraft">
                            <option :value="{draft:''}">- {{$t("documents.selectModel")}} -</option>
                            <option v-for="draft in drafts" :value="draft" :key="draft.id">{{ draft.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row" v-if="activeMethod === 'attachment'">
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" class="form-control" :placeholder="$t('documents.emailSubject')"
                                 v-model="mailer.separateSubject">
                        </div>
                        <div class="form-group">
                          <Editor2 :content="mailer" :seperate="true"></Editor2>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" class="form-control" :placeholder="$t('documents.emailPDFSubject')"
                                 v-model="mailer.subject">
                        </div>
                        <div class="form-group">
                          <Editor2 :content="mailer" :seperate="false"></Editor2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <button type="button" class="btn btn-outline-secondary" @click="showPdf = false"><i class="fa-duotone fa-xmark-circle"></i> {{$t("globals.cancel")}}</button>
                  </div>
                  <div class="col-6">
                    <button type="button" class="btn btn-outline-primary float-end"><i class="fa-duotone fa-paper-plane"></i> {{$t("globals.send")}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-if="modalsList.document">
        <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-body">
                <Edit :document="{}"  :modal="true"/></div>
            </div>
          </div>
        </div>

      </Modal>
      <Modal v-if="showContract">
        <div class="modal show" style="display: block;">
          <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <h1>Todo: Show Contract/Document</h1>
            <button @click="showContract = false" class="btn btn-warning">Close</button>
          </div></div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import {ref} from "vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2";
import indexedDbFunctions from "@/assets/js/indexedDbFunctions";
import Edit from "@/routing/core/Documents/Edit";

export default {
  name: "CustomerDocuments",
  computed: {
    ...mapGetters(["getEmployees", "customerActive", "modalsList"]),
  },
  components: {
    Editor2, Edit
  },

  data() {
    return {
      activeMethod: 'mail',
      activeSubtab: null,
      activeDraft: {draft: ''},
      withTemplate: false,
      attachments: [],
      filledVars: [],
      orderTabs: [
        {name: 'base', label: this.$t('documents.baseSettings'), icon: 'fa-cog'},
        {name: 'booking', label: this.$t('documents.billingData'), icon: 'fa-coin'},
        {name: 'docs', label: this.$t('documents.documentDispatch'), icon: 'fa-envelope'},
      ],
      activeOrderTab: 'base',
      pdfOptions: {
        header: 'with',
        bg: true,
      },
      sent: null,
      sentError: null,
      showPdf: false,
      showOrder: false,
      showContract: false,
      mailer: {
        subject: '',
        body: '',
        separateSubject: this.$t('documents.newDocument'),
        separateBody: this.$t('documents.documentAttachedaa'),
      },
      /** End Options for vueMailer **/
      showDocDropdown: false,
      documents: [],
      categories: [],
    }
  },
  props: {
    customer: {}
  },
  methods: {
    toggleDropdown() {
      this.showDocDropdown = !this.showDocDropdown
    },
    getCategories() {
      indexedDbFunctions.getObject(this.$store.state.dbName, this.$store.state.dbVersion, "config", "categories_documents").then((r) => {
        this.categories = r;
        if (this.$route.params.subtab === undefined) {
          this.$router.push({name: 'CustomersTabsSubtabs', params: {subtab: this.categories[0].title}});
        } else {
          for (let i = 0; i < this.categories.length; i++) {
            if (this.categories[i].title === this.$route.params.subtab) {
              this.activeSubtab = this.categories[i].title;
              this.getDocuments(this.categories[i].id);
            }
          }
        }
      });
    },
    getDocuments(catid) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "document/search?filter_category=" + catid + "&filter_customer=" + this.customerActive.id, {headers}).then((r) => {
        this.documents = r.data.result.data;
      })
    },
    showModalContract() {
      this.$store.dispatch('modals/open', {
        name: 'contract',
        data: {
          customer: this.customerActive.id
        }
      })
    },
    setDraft() {
      this.mailer.body = this.activeDraft.draft;
      this.mailer.subject = this.activeDraft.title;
    },
    draftSearch() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search", {headers}).then((r) => {
        this.drafts = r.data.result.data;
      });
    },
    showModalPDF() {
      this.$store.dispatch('modals/open', {
        name: 'pdf',
        data: {
          customer: this.customerActive.id
        }
      })
    }
  },
  watch: {
    customerActive() {
      //this.getDocuments()
      this.getCategories();
    },
    $route(to, from) {
      this.getCategories();
    }
  },
  created() {
    //this.getDocuments()
    this.getCategories();
  }
}
</script>
