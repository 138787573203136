<template>
  <div class="container-fluid"
       v-if="!getUser.acl || (getUser.acl.dashboard.read === 'yes' && getUser.acl.documents.umsatzs === 'yes')">
    <div class="row flex">
      <div class="col-lg-12">
        <div class="row" v-if="getMenu && getMenu.accounting">
          <div class="col-xl-3 col-lg-6 col-12">
            <small-card v-if="stats.currentOrders.title"
                        :title="stats.currentOrders.title"
                        :value="stats.currentOrders.value"
                        :percentage="stats.currentOrders.percentage"
                        :percentageColor="stats.currentOrders.percentageColor"
                        :iconClass="stats.currentOrders.iconClass"
                        :iconBackground="stats.currentOrders.iconBackground"
                        :detail="stats.currentOrders.detail"
                        directionReverse
            ></small-card>
          </div>
          <div class="col-xl-3 col-lg-6 col-12">
            <small-card v-if="stats.currentOffers.title"
                        :title="stats.currentOffers.title"
                        :value="stats.currentOffers.value"
                        :percentage="stats.currentOffers.percentage"
                        :percentageColor="stats.currentOffers.percentageColor"
                        :iconClass="stats.currentOffers.iconClass"
                        :iconBackground="stats.currentOffers.iconBackground"
                        :detail="stats.currentOffers.detail"
                        directionReverse
            ></small-card>
          </div>
          <div class="col-xl-3 col-lg-6 col-12">
            <small-card v-if="stats.currentMoneyIn.title"
                        :title="stats.currentMoneyIn.title"
                        :value="stats.currentMoneyIn.value"
                        :percentage="stats.currentMoneyIn.percentage"
                        :percentageColor="stats.currentMoneyIn.percentageColor"
                        :iconClass="stats.currentMoneyIn.iconClass"
                        :iconBackground="stats.currentMoneyIn.iconBackground"
                        :detail="stats.currentMoneyIn.detail"
                        directionReverse
            ></small-card>
          </div>
          <div class="col-xl-3 col-lg-6 col-12">
            <small-card v-if="stats.currentMoneyOut.title"
                        :title="stats.currentMoneyOut.title"
                        :value="stats.currentMoneyOut.value"
                        :percentage="stats.currentMoneyOut.percentage"
                        :percentageColor="stats.currentMoneyOut.percentageColor"
                        :iconClass="stats.currentMoneyOut.iconClass"
                        :iconBackground="stats.currentMoneyOut.iconBackground"
                        :detail="stats.currentMoneyOut.detail"
                        directionReverse
            ></small-card>
          </div>
        </div>
        <div class="row" v-if="getMenu && getMenu.accounting">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <LineChart v-if="chartData.labels" :chartData="chartData" class="boxWhite" :options='chartOptions'/>
            </div>
          </div>
        </div>
        <div class="row" v-if="getMenu && getMenu.customers">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <LineChart v-if="chartDataCustomers.labels" :chartData="chartDataCustomers" class="boxWhite" :options='chartOptions'/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-4">
            <div class="card">

              <h6 class="card-header">{{ $t("dashboard.cardHeader.customerEmployees") }}</h6>

              <!-- Tabs -->
              <ul class="nav nav-tabs nav-justified" role="tablist">
                <li class="nav-item">
                  <a class="nav-link " @click="activeEmployeeTab = 'contacts'"  role="tab" :class="(activeEmployeeTab === 'contacts') ? 'active': ''">
                    Kontakte
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  @click="activeEmployeeTab = 'offers'"  role="tab" :class="(activeEmployeeTab === 'offers') ? 'active': ''">
                    Erstangebote
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  @click="activeEmployeeTab = 'orders'"  role="tab" :class="(activeEmployeeTab === 'orders') ? 'active': ''">
                    Aufträge
                  </a>
                </li>
              </ul>

              <statisticEmployeeTable v-if="activeEmployeeTab === 'contacts' && statsByEmployeeNews" cType="filter_owner_id"  :stats="statsByEmployeeNews"/>
              <statisticEmployeeTable v-if="activeEmployeeTab === 'offers' && statsByEmployeeOffers" cType="filter_owner_id"  :stats="statsByEmployeeOffers"/>
              <statisticEmployeeTable v-if="activeEmployeeTab === 'orders' && statsByEmployeeOrders" cType="filter_owner_id"  :stats="statsByEmployeeOrders"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-4">
            <BarChart v-if="barChartData && Object.keys(barChartData).length > 0" :chartData="barChartData"
                      class="boxWhite" :options="barChartOptions"/>
          </div>

        </div>
        <div class="row">
          <div class="col-xl-6 mb-4">
            <div class="card">
              <h6 class="card-header">{{ $t("dashboard.cardHeader.customerType") }}</h6>
              <statisticCustomerTable :cType="'filter_type'" :stats="statsByType"/>
            </div>
          </div>
          <div class="col-xl-6  mb-4">
            <div class="card">
              <h6 class="card-header">{{ $t("dashboard.cardHeader.customerPriority") }}</h6>
              <statisticCustomerTable :cType="'filter_prio'" :stats="statsByPrio"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-4">
            <div class="card">
              <h6 class="card-header">{{ $t("dashboard.cardHeader.customerAcquisition") }}</h6>
              <statisticCustomerTable :cType="'filter_akquise'" :stats="statsByAkquise"/>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="card">
              <h6 class="card-header">{{ $t("dashboard.cardHeader.customerCategories") }}</h6>
              <statisticCustomerTable :cType="'filter_category'" :stats="statsByCat"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex">
      <div class="col-lg-12">
        <div class="card">
          <h6 class="card-header">Kontakte nach Land</h6>
          <statisticCustomerTable :cType="'filter_country'" :stats="statsByCountry"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div class="row flex">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="card" style="border: 0;">
              <div class="card-body">
                <img src="@/assets/img/sketch_connect_intro.jpg"
                     style="display: block; margin-left: auto; margin-right: auto; margin-bottom: 24px;margin-top: 24px;"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SmallCard from "@/routing/core/Dashboard/statistic/SmallCard";
import statisticCustomerTable from "@/routing/core/Dashboard/statistic/CustomerTable";
import statisticEmployeeTable from "@/routing/core/Dashboard/statistic/EmployeeTable";
import axios from "axios";
import {LineChart, BarChart} from 'vue-chart-3';
import {Chart, registerables} from 'chart.js';
import {mapGetters, mapActions} from "vuex";

Chart.register(...registerables);
export default {
  name: "dashboard-default",
  data() {
    return {
      options: {
        height: '300px',
        size: 0
      },
      activeEmployeeTab: 'contacts',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,

        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },

            ticks: {
              display: true,
              padding: 10,
              color: "#ccc",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },

          },
          x: {
            grid: {
              drawBorder: true,
              display: false,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },

          },
        },
      },

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend:{
            display: false,
          },
          tooltip:{
            interaction: {

            }
          }
        },
        // add defining border to each stacked bar in the chart

      },
      chartData: {},
      chartDataCustomers: {},
      barChartData: {},
      stats: {
        currentOffers: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBatteryFull",
          detail: "",
          iconBackground: "bg-gradient-primary",
        },
        currentOrders: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconArrow90degDown",
          iconBackground: "bg-gradient-danger",
          detail: "",
        },
        currentMoneyOut: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBookmark",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "",
        },
        currentMoneyIn: {
          title: "",
          value: "",
          percentage: "",
          iconClass: "BIconBookmark",
          percentageColor: "text-warning",
          iconBackground: "bg-gradient-warning",
          detail: "",
        },
      },
      statsByType: {},
      statsByPrio: {},
      statsByAkquise: {},
      statsByCat: {},
      statsByEmployee: {},
      statsByCountry: {},
      statsByEmployeeNews: {},
      statsByEmployeeOffers: {},
      statsByEmployeeOrders: {},
    };
  },
  components: {
    SmallCard,
    LineChart,
    BarChart,
    statisticCustomerTable,
    statisticEmployeeTable
  },
  methods: {
    ...mapActions(["getPlugins"]),
    getStatsTypes() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/types", {headers}).then((r) => {
        VueInner.statsByType = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsEmployees() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/employees", {headers}).then((r) => {
        VueInner.statsByEmployeeNews = r.data.result.data.dataNews;
        VueInner.statsByEmployeeOffers = r.data.result.data.dataOffers;
        VueInner.statsByEmployeeOrders = r.data.result.data.dataOrders;
        VueInner.barChartData = r.data.result.data.chart;
        VueInner.loading = false;
      });
    },
    getStatsPrio() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/prio", {headers}).then((r) => {
        VueInner.statsByPrio = r.data.result.data;
        VueInner.loading = false;
      })
    },
    getStatsAkquise() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/akquise", {headers}).then((r) => {
        VueInner.statsByAkquise = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsCountry() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/countries", {headers}).then((r) => {
        VueInner.statsByCountry = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsCat() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/categories", {headers}).then((r) => {
        VueInner.statsByCat = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsDocumentOrders() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/documents/currentOrders", {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentOrders.title = r.data.result.data.items + " " + this.$t("dashboard.openOrders");
          VueInner.stats.currentOrders.value = VueInner.$filters.priceEuro(r.data.result.data.brutto.open)
          VueInner.stats.currentOrders.percentage = VueInner.$filters.priceEuro(r.data.result.data.brutto.complete);
          VueInner.stats.currentOrders.detail = this.$t("dashboard.totalVolume");
        }
        VueInner.loading = false;
      });
    },
    getStatsDocumentOffers() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/documents/currentOffers", {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentOffers.title = r.data.result.data.items + " " + this.$t("dashboard.openOffers");
          VueInner.stats.currentOffers.value = VueInner.$filters.priceEuro(r.data.result.data.brutto.complete)
          VueInner.stats.currentOffers.percentage = VueInner.$filters.priceEuro(r.data.result.data.brutto.highest);
          VueInner.stats.currentOffers.detail = this.$t("dashboard.highestOffer");
        }
        VueInner.loading = false;
      });
    },
    getStatsInvoices() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/invoices/summary", {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.stats.currentMoneyIn.title = r.data.result.data.in.items + " " + this.$t("dashboard.openOutgoingInvoices");
          VueInner.stats.currentMoneyIn.value = VueInner.$filters.priceEuro(r.data.result.data.in.complete)
          VueInner.stats.currentMoneyIn.percentage = VueInner.$filters.priceEuro(r.data.result.data.in.thisYear);
          VueInner.stats.currentMoneyIn.detail = this.$t("dashboard.fromCurrentYear");
          VueInner.stats.currentMoneyOut.title = r.data.result.data.out.items + " " + this.$t("dashboard.openIncomingInvoices");
          VueInner.stats.currentMoneyOut.value = VueInner.$filters.priceEuro(r.data.result.data.out.complete)
          VueInner.stats.currentMoneyOut.percentage = VueInner.$filters.priceEuro(r.data.result.data.out.thisYear);
          VueInner.stats.currentMoneyOut.detail = this.$t("dashboard.fromCurrentYear");
        }
        VueInner.loading = false;
      });
    },
    getStatsInvoicesYear() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

        axios.get(this.$store.state.apiUrl + "statistic/invoices/year", {headers}).then((r) => {
          if (r.data.result && r.data.result.counter) {
            VueInner.chartData = r.data.result.data;
          }
          VueInner.loading = false;
        });

    },
    getStatsCustomersYear() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // QUICK TEST TODO-PLUGIN

        axios.get(this.$store.state.apiUrl + "statistic/customers/year", {headers}).then((r) => {
          if (r.data.result && r.data.result.counter) {
            VueInner.chartDataCustomers = r.data.result.data;
          }
          VueInner.loading = false;
        });

    },
  },
  computed: {
    ...mapGetters(["getMenu", "getUser"])
  },
  created() {
    this.getStatsTypes();
    this.getStatsPrio();
    this.getStatsAkquise();
    this.getStatsCountry();
    this.getStatsCat();
    this.getStatsInvoicesYear();
    this.getStatsCustomersYear();
    this.getStatsEmployees();


      this.getStatsDocumentOffers();
      this.getStatsDocumentOrders();
      this.getStatsInvoices();

  }
};
</script>
<style>
.Dashboard table tbody tr:last-child td {
  border-bottom: 0;
}

.Dashboard .ps > table {
  margin-bottom: 0;
}
</style>


