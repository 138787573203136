<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs.costNumbers.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
      <div  class="draft-item" v-for="draft in getConfigs.costNumbers"
            :key="draft.id">
        <Headline :draft="draft"/>
      </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Accounting/CostNumbers/Layouts/Headline.vue";

export default {
  name: "DraftSearch",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  components: {
    Headline,
  },

  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
