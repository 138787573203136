<template>
  <div class="sketch_content_wrap">
    <div class="sketch-row">
      <div class="columns large-12 medium-12 small-12"><h2 class="blue">Einstellungen <span>Workflow-Module</span></h2>
      </div>
    </div>
    <!--    <div class="sketch-row">-->
    <!--      <div class="columns xlarge-push-3 large-push-3 xlarge-6 large-6 medium-12 small-12">-->
    <!--        <div class="card mb-3 config-card">-->
    <!--          <router-link to="config/global" class="config-link">-->
    <!--            <div class="card-body text-center">-->
    <!--              <i class="fa-duotone fa-cog fa-4x mb-3"></i>-->
    <!--              <h3>Globale Einstellungen</h3>-->
    <!--            </div>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="configWrap purple">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-user-gear"></i> Kategorien Aufgaben</h3></div>
      </div>
      <div class="sketch-row">
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/workflow/tracker" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-handshake-simple fa-3x mb-3"></i>
                <h3>Tracker</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/workflow/states" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-folder-user fa-3x mb-3"></i>
                <h3>Status</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12" v-if="$store.state.development">
          <div class="card mb-3 config-card"  >
            <router-link to="/config/workflow/prio" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-user-tag fa-3x mb-3"></i>
                <h3>Prioritäten</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12" v-if="$store.state.development">
          <div class="card mb-3 config-card"  >
            <router-link to="/config/workflow/global" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-ticket fa-3x mb-3"></i>
                <h3>Ticket System</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SidenavItem from "@/sublayouts/Sidenav/SidenavItem.vue";

export default {
  name: "ConfigBasic",
  components: {SidenavItem}
}
</script>
<style scoped>
</style>
