<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 show"
    id="sidenav-collapse-main"
  >

    <perfect-scrollbar>
    <ul class="navbar-nav">

      <li class="nav-item" v-if="(getMenu && getMenu.dashboard )">
        <sidenav-item
            url="/dashboard"
            :class="getRoute() === 'dashboard' ? 'active' : ''"
            :navText="'Dashboard'"
            :bicon="'fa-table-columns'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="(getMenu && getMenu.customers && (! getUser.acl ||  getUser.acl.customers && getUser.acl.customers.read && (!getUser.acl || getUser.acl.customers.read === 'all' || getUser.acl.customer.read === 'own')))">
      <sidenav-item
          url="/customers"
          :class="getRoute() === 'customers' ? 'active' : ''"
          :navText="'Kunden'"
          :bicon="'fa-users'"
      >
      </sidenav-item>
      </li>
      <li class="nav-item orange" v-if="(getMenu && getMenu.dfb)">
        <sidenav-item
          url="/dfb"
          :class="getRoute() === 'dfb' ? 'active' : ''"
          :navText="'Trainerbewerbungen'"
          :bicon="'fa-message-lines'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="(getMenu && getMenu.documents)">
        <sidenav-item
            url="/documents"
            :class="getRoute() === 'documents' ? 'active' : ''"
            :navText="'Aufträge'"
            :bicon="'fa-file-contract'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getMenu && getMenu.products)">
        <sidenav-item
            url="/products"
            :class="getRoute() === 'products' ? 'active' : ''"
            :navText="'Produkte'"
            :bicon="'fa-tags'"
        >
        </sidenav-item>
      </li>

<!--      <li class="nav-item" v-if="(getMenu && getMenu.abonnements)">-->
<!--        <sidenav-item-->
<!--            url="/abonnements"-->
<!--            :class="getRoute() === 'abonnements' ? 'active' : ''"-->
<!--            :navText="'Abonnements'"-->
<!--            :bicon="'fa-rectangle-history-circle-plus'"-->
<!--        >-->
<!--        </sidenav-item>-->
<!--      </li>-->

      <li class="nav-item" v-if="(getMenu && getMenu.activities)">
        <sidenav-item
            url="/activities"
            :class="getRoute() === 'activities' ? 'active' : ''"
            :navText="'Aktivitäten'"
            :bicon="'fa-chart-network'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getMenu && getMenu.queue)">
        <sidenav-item
            url="/queue"
            :class="getRoute() === 'queue' ? 'active' : ''"
            :navText="'Warteschlange'"
            :bicon="'fa-snake'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="$store.state.development">
        <sidenav-item
          url="/config/basic"
          :class="$route.path === '/config/basic' ? 'active' : ''"
          :navText="'Einstellungen'"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>

      <template v-if="getMenu && (getMenu.employees || getMenu.times)">
      <li class="nav-category" >

        <div class="category-link category-link-employees fw-bold">Personal</div>

      </li>

      <li class="nav-item green" v-if="(getMenu && getMenu.employees)">
        <sidenav-item
            url="/employees"
            :class="getRoute() === 'employees' ? 'active' : ''"
            :navText="'Mitarbeiter'"
            :bicon="'fa-id-card-clip'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item green" v-if="(getMenu && getMenu.times)">
        <sidenav-item
            url="/times"
            :class="getRoute() === 'times' ? 'active' : ''"
            :navText="'Zeiterfassung'"
            :bicon="'fa-calendar-clock'"
        >
        </sidenav-item>
      </li>
<!--v-if="getMenu && getMenu.legal"-->
      <li class="nav-item green" v-if="getMenu && (getMenu.employees || getMenu.times) && $store.state.development" >
        <sidenav-item
          url="/config/employee"
          :class="$route.path === '/config/Employee' ? 'active' : ''"
          :navText="'Einstellungen'"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>
      </template>
      <li class="nav-category" v-if="getMenu && (getMenu.tasks || getMenu.drafts)">

        <div class="category-link category-link-workflows fw-bold">Workflow</div>

      </li>

      <li class="nav-item pink" v-if="(getMenu && getMenu.tasks && (!getUser.acl || getUser.acl.documents.umsatzs === 'yes')) ">
        <sidenav-item
            url="/tasks"
            :class="getRoute() === 'tasks' ? 'active' : ''"
            :navText="'Aufgaben'"
            :bicon="'fa-clipboard-list-check'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item pink" v-if="(getMenu && getMenu.events)" >
        <sidenav-item
            url="/events"
            :class="getRoute() === 'events' ? 'active' : ''"
            :navText="'Veranstaltungen'"
            :bicon="'fa-calendar-star'"
        >
        </sidenav-item>

      </li>
      <li class="nav-item pink" v-if="(getMenu && getMenu.drafts && (!getUser.acl || getUser.acl.documents.umsatzs === 'yes'))">
        <sidenav-item
            url="/drafts"
            :class="getRoute() === 'drafts' ? 'active' : ''"
            :navText="'Vorlagen'"
            :bicon="'fa-layer-group'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item pink" v-if="$store.state.development">
        <sidenav-item
          url="/config/workflow"
          :class="getRoute() === '/config/workflow' ? 'active' : ''"
          :navText="'Einstellungen'"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>
<template v-if="(!getUser || !getUser.acl || ( getUser.acl && getUser.acl.documents &&  getUser.acl.documents.umsatzs === 'yes'))">


      <li class="nav-category" v-if="getMenu && (getMenu.files || getMenu.sepa || getMenu.accounting ||  getMenu.legal) ">

        <div class="category-link category-link-accounting fw-bold">Buchhaltung</div>

      </li>
      <li class="nav-item red" v-if="(getMenu && getMenu.files)">
        <sidenav-item
            url="/files"
            :class="getRoute() === 'files' ? 'active' : ''"
            :navText="'Dateien'"
            :bicon="'fa-file-arrow-up'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item red" v-if="(getMenu && getMenu.sepa)">
        <sidenav-item
            url="/sepa"
            :class="getRoute() === 'sepa' ? 'active' : ''"
            :navText="'SEPA-Mandate'"
            :bicon="'fa-wallet'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item red" v-if="(getMenu && getMenu.accounting)">
        <sidenav-item
            url="/accounting"
            :class="getRoute() === 'accounting' ? 'active' : ''"
            :navText="'Buchhaltung'"
            :bicon="'fa-receipt'"
        >
        </sidenav-item>
      </li>



      <!--      <li class="nav-item red" v-if="(getMenu && getMenu.bankaccountmovements)">-->
<!--        <sidenav-item-->
<!--            url="/bankaccountmovements"-->
<!--            :class="getRoute() === 'bankaccountmovements' ? 'active' : ''"-->
<!--            :navText="'Kontobewegungen'"-->
<!--            :bicon="'fa-credit-card'"-->
<!--        >-->
<!--        </sidenav-item>-->
<!--      </li>-->

      <li class="nav-item red" v-if="(getMenu && getMenu.stats)">
        <sidenav-item
            url="/stats"
            :class="getRoute() === 'stats' ? 'active' : ''"
            :navText="'Berichte'"
            :bicon="'fa-chart-column'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item red" v-if="(getMenu && getMenu.legal)">
        <sidenav-item
            url="/legal"
            :class="getRoute() === 'legal' ? 'active' : ''"
            :navText="'Rechtliches'"
            :bicon="'fa-gavel'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item red" v-if="(getMenu && getMenu.accounting)">
        <sidenav-item
          url="/config/accounting"
          :class="$route.path === '/config/accounting' ? 'active' : ''"
          :navText="'Einstellungen'"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>





</template>

      <li class="nav-item orange" v-if="!$store.state.loggedInState">
        <sidenav-item
          url="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="'Sign In'"
          :bicon="'fa-sign-in-alt'"
        >
          <template v-slot:icon>
            <i class="fa-duotone fa-right-to-bracket"></i>
          </template>
        </sidenav-item>
      </li>
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/signup"-->
<!--          :class="getRoute() === 'signup' ? 'active' : ''"-->
<!--          :navText="this.$store.state.isRTL ? 'اشتراك' : 'Sign Up'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-collection text-info text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->

    </ul></perfect-scrollbar>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer bottom-0">
    <sidenav-card
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import {mapGetters} from 'vuex';
export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {

      title: "sketch.connect",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  computed: {
    ...mapGetters(["getUser", "getMenu"])
  },
  components: {
    SidenavItem,
    SidenavCard
  },
  created() {

  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>

<style lang="scss">

$orange:  #fd7e14 !default;
.nav-category {

  width: 100%;

  .category-link {

    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 24px;
    padding-left: 0;
    padding-right: 1rem;
    color: #344767;
    font-size: 0.8rem;

    &.category-link-employees {

      border-bottom: .09rem solid #42a62b;

    }

    &.category-link-workflows {

      border-bottom: .09rem solid #7b1991;

    }

    &.category-link-accounting {

      border-bottom: .09rem solid #ff4e3d;

    }

    &.category-link-configuration {

      border-bottom: .09rem solid $orange;

    }

  }

}

</style>
