<template>

  <div class="container-fluid">

    <div class="row mb-3">

      <div class="col-12">

        <div class="card">

          <div class="card-body">

            <div class="row">

              <div class="col-3">

                <div class="form-group">

                  <label for="employee">Mitarbeiter</label>
                  <select id="employee" class="form-control" v-model="filter_owner_id">
                    <option value="">Mitarbeiter wählen</option>
                    <option v-for="employee in employees" :value="employee.id" v-bind:key="employee.id">{{ employee.e_name }}</option>
                  </select>

                </div>

              </div>

              <div class="col-3">

                <div class="form-group">

                  <label for="month">Monat</label>
                  <select id="month" class="form-control" v-model="filter_month">
                    <option value="">Monat wählen</option>
                    <option value="01">Januar</option>
                    <option value="02">Februar</option>
                    <option value="03">März</option>
                    <option value="04">April</option>
                    <option value="05">Mai</option>
                    <option value="06">Juni</option>
                    <option value="07">Juli</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Dezember</option>
                  </select>

                </div>

              </div>

              <div class="col-3">

                <div class="form-group">

                  <label for="year">Jahr</label>
                  <select id="year" class="form-control" v-model="filter_year">
                    <option value="">Jahr wählen</option>
                    <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                  </select>

                </div>

              </div>

              <div class="col-3">

                <label>⠀</label>
                <button type="button" class="btn btn-secondary w-100" @click="filter()">Suchen</button>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-12">

        <div class="card">

          <div class="card-body">

            <div class="row mb-3">

              <div class="col-3">
                <p class="text-sm text-secondary fw-bold mb-0">Tag</p>
              </div>

              <div class="col-3">
                <p class="text-sm text-secondary fw-bold mb-0">Summe</p>
              </div>

              <div class="col-3">
                <p class="text-sm text-secondary fw-bold mb-0">Grund</p>
              </div>

            </div>

            <div class="row mb-3" v-for="currentTime in currentTimes" v-bind:key="currentTime.id">

              <div class="col-3">
                <p class="text-sm text-secondary mb-0">{{ currentTime.day }}</p>
              </div>

              <div class="col-3">
                <p class="text-sm text-secondary mb-0">{{ currentTime.sum }}</p>
              </div>

              <div class="col-3">
                <p class="text-sm text-secondary mb-0">{{ currentTime.reason }}</p>
              </div>

              <div class="col-3">
                <button type="button" class="btn btn-secondary btn-sm">Details</button>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import axios from "axios";

export default {

  name: 'TimesOverview',

  data() {
    return {
      employees: {},
      currentTimes: {},
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,

      filter_query: '',
      filter_owner_id: '',
      filter_month: '',
      filter_year: '',

    }
  },

  methods: {

    getEmployees() {

      let VueInner = this;
      const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "employee/search", {headers}).then((r) => {
        VueInner.employees = r.data.result.data;
      });

    },

    filter() {

      this.filter_query = '';

      if (this.filter_owner_id) {
        this.filter_query += '?filter_owner=' + this.filter_owner_id;
      }

      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }

      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }

      this.getCurrentTimes();

    },

    getCurrentTimes() {

      let VueInner = this;

      const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "time/search" + this.filter_query, {headers}).then((r) => {
        VueInner.currentTimes = r.data.result.data;
      });

    }

  },

  created() {
    this.getEmployees();
  },

  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2015}, (value, index) => 2016 + index)
    }
  }

}

</script>