<template>
  <div class="btn-group me-3 ms-3" v-if="(taskActive || timerRunning) && ( $route.name === 'TasksDetails' || $route.name === 'TasksTabs'  ) ">
    <template v-if="!timerRunning">
      <button @click="start" class="btn btn-outline-primary purple btn-sm" tooltip="Task: Timer starten" position="left">
        <i class="fa-duotone fa-stopwatch"></i>
      </button>
    </template>
    <template v-else>
      <template v-if="timerPaused">
        <div class="btn btn-outline-secondary btn-sm">{{ formatTime }}</div>
      </template>
      <template v-else>
      <div class="btn btn-outline-primary btn-sm">{{ formatTime }}</div>
      </template>
      <button @click="pause" class="btn btn-outline-secondary btn-sm" tooltip="Timer pausieren" position="bottom">
        <i :class="timerPaused ? 'fa-duotone fa-play-circle' : 'fa-duotone fa-pause-circle'"></i>
      </button>
      <button @click="stop" class="btn btn-outline-primary btn-sm" tooltip="Timer stoppen" position="bottom">
        <i class="fa-duotone fa-stop-circle"></i>
      </button>
    </template>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "Timer",
  data() {
    return {
      startTime: null,
      elapsedTime: 0,
      timerInterval: null,
      timerRunning: false,
      timerPaused: false  // New property to track timer paused state
    };
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs"]),
    formatTime() {
      const hours = Math.floor(this.elapsedTime / 3600);
      const minutes = Math.floor((this.elapsedTime % 3600) / 60);
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}`;
    }
  },
  methods: {
    start() {
      if (!this.timerRunning) {
        this.startTime = new Date().getTime();
        this.timerInterval = setInterval(() => {
          this.elapsedTime = Math.floor((new Date().getTime() - this.startTime) / 1000);
        }, 1000);
        this.timerRunning = true; // Set timerRunning to true
      }
    },
    pause() {
      if (this.timerRunning) {
        if (this.timerPaused) {
          clearInterval(this.timerInterval);
          this.timerPaused = false;
        } else {
          this.startTime = new Date().getTime() - (this.elapsedTime * 1000);
          this.timerInterval = setInterval(() => {
            this.elapsedTime = Math.floor((new Date().getTime() - this.startTime) / 1000);
          }, 1000);
          this.timerPaused = true;
        }
      }
    },
    stop() {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
      this.elapsedTime = 0;
      this.timerRunning = false; // Set timerRunning to false
    }
  }
};
</script>

<style scoped>
</style>
