<template>
  <div class="card">
    <div class="card-header"><h6 class="inline"><i class="fa-id-card-clip fa-duotone"></i> Mitarbeiter</h6>
      <div class="float-end">
        <div class="btn-group me-4">
          <template v-if="e.id">
          <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteEmployee()"
                  v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-trash"></i> Löschen
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm" @click="lockEmployee()"
                  v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-lock"></i> Sperren
          </button>
          <button type="button" class="btn btn-outline-secondary btn-sm" @click="unlockEmployee()"
                  v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-unlock"></i> Entsperren
          </button>
          </template>
          <button type="button" class="btn btn-outline-primary btn-sm" @click="updateEmployee()"
                  v-if="getUser.acl.employees.update === 'all'"><i class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
        <button type="button" class="btn btn-close" @click="closeProject();"></button>
      </div>
    </div>
    <ul class="tabNav">
      <li v-for="cat in employeeTabs" :key="cat.name"
          @click="changeTab(cat.name)"
          :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i><br><small>
        {{ cat.label }}</small>
      </li>
      <li @click="changeTab('mail')" :class="(activeSubtab === 'mail') ? 'active' : ''"
          v-if="getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-envelope"></i><br><small>E-Mails</small>
      </li>
      <li @click="changeTab('timesheet')" :class="(activeSubtab === 'timesheet') ? 'active' : ''"
          v-if="getMenu.times && getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-calendar"></i><br><small>Dienstplan</small>
      </li>
      <li @click="changeTab('acl')" :class="(activeSubtab === 'acl') ? 'active' : ''"
          v-if="getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-hammer"></i><br><small>Zugriffsrechte</small>
      </li>
    </ul>
    <div class="card-body">
      <template v-if="activeSubtab === 'details'">
        <div class="row">
          <div class="col-9">
            <div class="form-group">
              <label for="e_name">Name</label>
              <input type="text" class="form-control" name="e_name" id="e_name" v-model="e.e_name"/>
            </div>
          </div>
          <div class="col-3">
            <label for="e_category">Kategorie</label>
            <select name="e_category" id="e_category" class="form-select" v-model="e.e_catid">
              <option value="">Bitte wählen</option>
              <option v-for="cat in getConfigs.categories_employees" :value="cat.id">{{ cat.title }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Telefon</label>
              <input type="text" class="form-control" name="phone" v-model="e.phone"/>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="e_email">E-Mail</label>
              <input type="text" class="form-control" name="e_email" id="e_email" v-model="e.e_email"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6" v-if="getPlugins.phone && getPlugins.phone.placetel">
            <div class="form-group">
              <label>Telefon IP</label>
              <input type="text" class="form-control" name="phone_ip" v-model="e.phone_ip"/>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Mitarbeiter Farbe</label>
              <input type="color" class="form-control" name="phone_ip" v-model="e.color"/>
            </div>
          </div>
          <div class="col-3" v-if="e && e.id">
            <div class="form-group">
              <label>Mitarbeiter Bild</label>
              <input type="file" ref="fileInput" class="form-control"  @change="onImageChange" accept="images/*" />
              <div v-if="e.imageSource">
                <img :src="'data:'+e.imageMimeType+';base64,'+e.imageSource" :alt="e.imageMimeType" />
                <button class="btn btn-danger" @click="e.imageSource = ''; e.imageMimeType = ''">X</button>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="open_times">Notiz</label>
              <Editor2 v-model="e.open_times"/>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'acl'">
        <Acl :employee="e" @acl-changed="updateRights"/>
      </template>
      <template v-if="activeSubtab === 'timesheet'">
        <div class="row">
          <div class="col-6">
            <div class="globals" v-if="e.customFields && e.customFields.timesGlobal">
              <div class="input-group">
                <label><small>Durschnittliche tägliche Arbeitszeit (Stunden)</small></label>

              <input type="number" step="0.01" min="0.00"
                     v-model="e.customFields.timesGlobal.daily" placeholder="Tägliche Durchschnittszeit"> </div>
            </div>
          </div>
          <div class="col-6">
            <button type="button" class="copyGroupTime btn btn-outline-primary float-end" @click="addNewTime"><i
              class="fa-duotone fa-circle-plus"></i> Tag hinzufügen
            </button>
          </div>
        </div>
        <div class="groupTimes" v-if="sortedTimes">
          <div class="day" v-for="(times, day) in sortedTimes" :key="day">
            <strong><small>{{ getDayByDay(times.day) }} <span class="countDay">{{ calculateDayHours(times) }} Stunde(n)</span></small></strong>
            <div class="groupTime">
              <div class="row">
                <div class="col-3"><select class="form-select small" required v-model.number="times.day">
                  <option v-for="option in daysOptions" :value="option.value" :key="option.label">
                    {{ option.label }}
                  </option>
                </select></div>
                <div class="col-3"><input class="form-control small" required type="time" v-model="times.from"></div>
                <div class="col-3"><input class="form-control small" required type="time" v-model="times.till"></div>
                <div class="col-3">
                  <button class="deleteIt btn btn-outline-danger btn-sm" @click="deleteTime(times)"><i
                    class="fa-duotone fa-trash-can"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sumWeeks" v-if="sumWeek && averageDayHours">
          <strong>Wochenarbeitszeit: </strong>{{ sumWeek }}<br>
          <strong>Durchschnittliche Tagesarbeitszeit: </strong>{{ averageDayHours }}
          <div
            v-if="e.customFields && e.customFields.timesGlobal && averageDayHours !== e.customFields.timesGlobal.daily"
            class="alert alert-primary">
            Tagesarbeitszeit entspricht nicht der Einstellung!
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'mail'">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label class="me-3">Separates Mailing</label>

              <div class="btn-group btn-group-yesno" role="group">
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_yes" value="1"
                       v-model="e.mail_settings.use_own_mailing">
                <label class="btn btn-outline-success" for="use_own_mailing_yes">Ja</label>
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_no" value="0"
                       v-model="e.mail_settings.use_own_mailing">
                <label class="btn btn-outline-danger" for="use_own_mailing_no">Nein</label>
              </div>
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>Absenderadresse</label>
              <input class="form-control" type="text" v-model="e.mail_settings.mailfrom">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>Absendername</label>
              <input class="form-control" type="text" v-model="e.fromname">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>Server</label>
              <input class="form-control" type="text" v-model="e.smtphost">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>Port</label>
              <input class="form-control" type="text" v-model="e.smtpport">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>SMTP-Sicherheit</label>
              <select class="form-control" v-model="e.mail_settings.smtpsecure">
                <option value="none">Keine</option>
                <option value="ssl">SSL/TLS</option>
                <option value="tls">STARTTLS</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label class="me-3">SMTP-Authentifizierung</label>
              <div class="btn-group btn-group-yesno" role="group">
                <input type="radio" class="btn-check" name="smtpauth" id="smtpauth_yes" value="1" v-model="e.mail_settings.smtpauth">
                <label class="btn btn-outline-success" for="smtpauth_yes">Ja</label>
                <input type="radio" class="btn-check" name="smtpauth" id="smtpauth_no" value="0" v-model="e.mail_settings.smtpauth">
                <label class="btn btn-outline-danger" for="smtpauth_no">Nein</label>
              </div>
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1' && e.mail_settings.smtpauth === '1'">
              <label>Benutzer</label>
              <input class="form-control" type="text" v-model="e.smtpuser">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1' && e.mail_settings.smtpauth === '1'">
              <label>Passwort</label>
              <input class="form-control" type="text" v-model="e.mail_settings.smtppass">
            </div>
            <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
              <label>Eigener E-Mail Abschluss (Grüße)</label>
              <Editor2 v-model="e.mail_settings.greetings"/>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Acl from "@/routing/core/Employees/Acl";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import employee from "../Config/Employee.vue";

export default {
  name: "EmployeeDetails",
  emits: ['closedEdit'],
  components: {
    Editor2, Acl
  },

  computed: {
    employee() {
      return employee
    },
    ...mapGetters(["getConfigs", "getUser", "getPlugins", "getMenu"]),
    checkAccess() {
      // Here you'll need to implement the ACL check logic
      // I'm returning true as a placeholder for now
      return true;
    },
    sortedTimes() {
      // Convert the times object into an array
      if (!this.e.customFields || !this.e.customFields.times) {
        return [];
      }
      return Object.values(this.e.customFields.times);
    },
    sumWeek() {
      // Calculate the sum of the week's hours
      let sum = 0;
      for (const day in this.sortedTimes) {
        sum += this.calculateDayHours(this.sortedTimes[day]);
      }
      return sum;
    },
    averageDayHours() {
      return this.sumWeek / Object.keys(this.sortedTimes).length;
    },

  },
  data() {
    return {
      e: {
        color: '#000000',
        rights: {},
        customFields: {
          times: {},
          timesGlobal: {
            daily: 0
          },
          images: null, // Hier wird das Bild gespeichert
        },
        mail_settings: {
          use_own_mailing: '0',
          greetings: '',
          mailfrom: '',
          smtpauth: '0',
          smtppass: '',
          smtpsecure: 'none',
          smtpuser: '',
        },
      },
      daysOptions: [
        {value: '1', label: 'Montag'},
        {value: '2', label: 'Dienstag'},
        {value: '3', label: 'Mittwoch'},
        {value: '4', label: 'Donnerstag'},
        {value: '5', label: 'Freitag'},
        {value: '6', label: 'Samstag'},
        {value: '0', label: 'Sonntag'}
      ],
      isNew: true,
      employeeTabs: [
        {name: 'details', label: 'Details', icon: 'fa-info'},
      ],
      activeSubtab: 'details',
    };
  },
  methods: {
    onImageChange() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0]; // Get the selected file

      let VueInner = this;
      if (file) {
        // Read the file as a Data URL to get base64 data
        const reader = new FileReader();
        reader.onload = () => {
          VueInner.e.imageMimeType = file.type; // Set the image MIME type

          VueInner.e.imageSource = reader.result.split(',')[1]; // Set the base64 image data
        };
        reader.readAsDataURL(file);
      }
    },
    formatValue(value) {
      if (typeof value !== 'number') {
        return '0.00';
      }
      return value.toFixed(2);
    },
    addNewTime() {
      // Create a default new time entry
      const newTime = {
        day: "1",  // Default to Monday or any other day
        from: "08:00",
        till: "17:00"
      };
      // Initialize e.customFields if it doesn't exist
      if (!this.e.customFields) {
        this.e.customFields = {
        };
      }
      // Initialize e.customFields.times if it doesn't exist
      if (!this.e.customFields.times) {
        this.e.customFields.times = {};
      }
      // Determine the next key
      const nextKey = Object.keys(this.e.customFields.times).length + 1;
      // Add the new time entry
      this.e.customFields.times[nextKey] = newTime;
    },
    getDayByDay(day) {
      // Convert the day to a number and find a match in the daysOptions array
      const matchingDay = this.daysOptions.find(option => option.value === String(day));
      // Return the label of the matching day or a default value if not found
      return matchingDay ? matchingDay.label : 'Unknown Day';
    },
    calculateDayHours(time) {
      return this.calcTimeDifference(time.from, time.till);
    },
    calcTimeDifference(from, till) {
      // Convert time strings to Date objects
      const fromDate = new Date(`1970-01-01T${from}:00Z`);
      const tillDate = new Date(`1970-01-01T${till}:00Z`);
      // Calculate difference in milliseconds and convert to hours
      const diffHours = (tillDate - fromDate) / (1000 * 60 * 60);
      return diffHours;
    },
    deleteTime(time, day) {
      // Delete a time entry
      this.sortedTimes[day] = this.sortedTimes[day].filter(t => t !== time);
    },
    closeProject() {
      this.$store.commit('closeModal', 'employee');
      this.$emit('closedEdit');
      // trigger custom Event newEmployee windows event
      const MyEvent = new CustomEvent('newEmployee');
      window.dispatchEvent(MyEvent);
    },
    deleteEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "employee/" + VueInner.e.id, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gelöscht.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },

    lockEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id + "/lock", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gesperrt.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    unlockEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id + "/unlock", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich entsperrt.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    getEmployee() {
      if (this.$route.params !== undefined && this.$route.params.id !== undefined) {
        let VueInner = this;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "employee/" + this.$route.params.id, {headers}).then((r) => {
          VueInner.e = r.data.result.data.employee;
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      }
    },
    updateEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.isNew) {
        axios.post(this.$store.state.apiUrl + "employee", VueInner.e, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich angelegt.");
          this.closeProject();
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      } else {
        axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id, VueInner.e, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gespeichert.");
          this.closeProject();
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      }
    },
    checkIfIsNew() {
      if (this.$route.params !== undefined && this.$route.params.id) {
        this.isNew = false;
      } else {
        this.e = {
          color: '#000000',
          rights: {},
          customFields: {
            times: {},
            timesGlobal: {
              daily: 0
            }
          },
          mail_settings: {
            use_own_mailing: '0',
            greetings: '',
            mailfrom: '',
            smtpauth: '0',
            smtppass: '',
            smtpsecure: 'none',
            smtpuser: '',
          }
        };
        this.isNew = true;
      }
    },
    updateRights(newRights) {
      this.e.rights = newRights;
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
    },
  },
  mounted() {
    this.getEmployee();
  },
  created() {
    this.checkIfIsNew();
  },
  watch: {
    $route(to, from) {
      this.getEmployee();
      this.checkIfIsNew();
    }
  },
};
</script>
<style scoped>
.inline {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 6px;
}
</style>
