<template>

  <div class="accountData">




        <div v-if="accountData && accountData.sorted">
          <div class="row">
            <div class="col-2"><span @click="saldenausgleich(accountData.sorted.key)" v-if="isKonto" class="btn">Saldenausgleich</span></div>
            <div class="col-10">
              <p class="hint pe-3" style="text-align: right"  v-if="accountData && accountData.sorted">{{accountData.sorted.account.title}}
              <template v-if="accountData.sorted.account.startSum"> (Startbetrag:
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum >= 0">+</template>
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum < 0">-</template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum < 0">+</template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum >= 0">-</template>
              {{ $filters.priceEuro(accountData.sorted.account.startSum) }})
              </template>
            </p>
            </div>
          </div>

          <div class="calcs row p-2 pt-0" style="max-width: 100%">
            <div class="calc col-lg-3 p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Konto</span><br>{{ accountData.sorted.key }} </p>
             </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Soll</span>     <br>{{ $filters.priceEuro(accountData.sorted.soll.sum) }}</p>
             </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Haben</span>    <br>{{ $filters.priceEuro(accountData.sorted.haben.sum) }}</p>
            </div>
            <div class="calc col-lg-3  p-0">
              <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Saldo</span><br> {{ $filters.priceEuro(accountData.sorted.diff.sum) }} {{accountData.sorted.diff.type}}</p>
            </div>
            <hr>
          </div> <perfect-scrollbar >
          <div class="calcs row p-2 pt-0" style="max-width: 100%">
            <div class="calc  p-0">

          <table  class="accountingDataTable table align-items-center p-2 mb-0" v-if="accountData.data && accountData.data.length > 0">
            <thead>
            <tr>
              <th>Belegdat.</th>
              <th>Belegfelder</th>
              <th class="align-right">Soll</th>
              <th class="align-right">Haben</th>
              <th class="align-right">Gegenkto</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="acc in accountData.data" :key="acc.id">
              <td>{{ $filters.formatDate(acc.datum) }}</td>

              <td>
                {{acc.beleg1}}
                <template v-if="acc.beleg2"><br><span class="hint">{{acc.beleg2}}</span></template>
              </td>

              <td class="align-right"><template v-if="acc.sh === 'S'">{{ $filters.priceEuro(acc.betrag) }}</template></td>
              <td class="align-right"><template v-if="acc.sh === 'H'">{{ $filters.priceEuro(acc.betrag) }}</template></td>
              <td class="align-right">{{acc.gegenkonto}}</td>
            </tr>

            </tbody>
          </table>

            </div>
          </div>
        </perfect-scrollbar>
        </div>



  </div>

</template>

<script>


export default {

  name: 'AccountData',

  data() {

    return {}

  },

  props: {

    accountData: {

      type: Object,

      required: true

    },
    isKonto: {
      type: Boolean,
      required: false
    }

  },


  created() {

  },
}

</script>
<style lang="scss" scoped>
.ps {
  height: 250px;
}
</style>