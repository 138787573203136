<template>
  <div class="draftLineWrap" :class="(draftActive && draftActive.id === draft.id) ? 'active' : ''" >
    <div class="lineitem l-unit">
      <template v-if="draft.default==='1' ">
        <i class="fa-duotone fa-check-circle"></i>
      </template>
    </div>
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>
    <div class="lineitem l-default">
      {{ draft.iban }}
    </div>
    <div class="lineitem l-default">
      {{ draft.money }}
    </div>
    <div class="lineitem l-default">
      {{ draft.start_month }}
    </div>
    <div class="lineitem l-cdefault">
      {{ draft.konto }}
    </div>
    <div class="lineitem l-price">
      {{ draft.id }}
    </div>

    <div class="lineitem last ml-auto">
      <template v-if="draftActive && draftActive.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigBankAccounts')"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees"])
  },
  methods: {
    setConfig(draft) {
      this.$store.dispatch("setActiveConfig", draft, 'ConfigBankAccounts');
      this.$router.push({name: 'ConfigBankAccountsDetails', params: {id: draft.id}})

    },
  }
}
</script>
