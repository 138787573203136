import {createApp, watch, nextTick} from "vue";
import App from "./App.vue";
import store from "./store";
import './registerServiceWorker'
import router from "./router";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import 'vue-universal-modal/dist/index.css';
import VueUniversalModal from 'vue-universal-modal';
import VueSimpleAlert from "vue3-simple-alert-next";
import ckeditor from '@ckeditor/ckeditor5-vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from 'vuejs3-logger';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from 'vue-multiselect'

// register globally
import {createI18n} from 'vue-i18n'
import messages from './lang';

const i18n = createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages
})
export const appInstance = createApp(App);
const isProduction = process.env.NODE_ENV === 'production';
const optionsLogger = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
appInstance.component('multiselect', Multiselect);
appInstance.use(i18n);


appInstance.use(VueLogger, optionsLogger);
appInstance.use(VueSimpleAlert);
appInstance.use(store);
appInstance.use(ckeditor);
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right'
};
appInstance.use(Toast, options);
appInstance.use(router);
appInstance.use(VueUniversalModal, {
  teleportTarget: '#modals',
});
appInstance.use(PerfectScrollbar, {
  watchOptions: true,
  options: {
    suppressScrollX: true
  }
});
appInstance.component("VudeDatePicker", VueDatePicker);
import logo from "@/assets/img/logo.png";
import avatar from "@/assets/img/avatar.svg";

appInstance.config.globalProperties.$filters = {
  employeeName(employees, id) {
    if (!employees.length) {
      return "Unbekannt";
    }
    let employee = employees.filter(obj => {
      return obj.id === id
    });
    employee = employee[0];
    if (employee && employee.e_name) {
      return employee.e_name;
    } else {
      return "Unbekannt";
    }
  },
  employeeIcon(employees, id, type) {
    if (!employees.length) {
      return avatar;
    }
    if (type === undefined) {
      type = 'id';
    }
    let employee = employees.filter(obj => {
      return obj[type] === id
    });
    employee = employee[0];
    if (employee && employee._image) {
      return employee._image;
    } else {
      if (employee && employee.e_name) {
       const svgDataURL =  this.generateSVGFromName(employee.e_name);
       return svgDataURL;
      } else {
        return avatar;
      }
    }
  },
  generateSVGFromName(name) {
    const svgWidth = 64;
    const svgHeight = 64;
    const fontSize = 32;
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("width", svgWidth);
    svg.setAttribute("height", svgHeight);
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "50%");
    text.setAttribute("y", "50%");
    text.setAttribute("text-anchor", "middle");
    text.setAttribute("dominant-baseline", "central");
    text.setAttribute("font-size", fontSize);
    text.setAttribute("font-family", "Arial");
    text.textContent = this.getInitials(name);

    svg.appendChild(text);
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const base64 = btoa(svgString);
    const dataURL = `data:image/svg+xml;base64,${base64}`;
    return dataURL;
  },
  getInitials(name) {
    const names = name.split(" | ")[1] || name;
    const initials = names
      .split(" ")
      .filter(n => n !== "|")
      .map(n => n[0])
      .join("")
      .slice(0, 2);
    return initials.toUpperCase();
  },
  renderLineBreaks(value) {
    if (value) {
      return value.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");

    }
  },
  priceEuro(value) {
    return Number(value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
  },
  formatDate(value) {
    if (value === null) {
      return ""
    }
    let date = new Date(value);
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    if (date.toLocaleDateString("de-DE", options) !== 'Invalid Date') {
      return date.toLocaleDateString("de-DE", options);
    }
  },
  formatTime(value) {
    let date = new Date(value);
    return date.toLocaleTimeString("de-DE") + " Uhr";
  },
  formatTimeShort(value){
    let date = new Date(value);
    return date.toLocaleTimeString("de-DE", {hour: '2-digit', minute: '2-digit'}) + " Uhr";
  },
  formatDateTime(value) {
   if (value === undefined || !value || value === '0000-00-00 00:00:00') {
     return "";
   }
    let date = new Date(value);
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    const timeOptions = {hour: '2-digit', minute: '2-digit'};


      return date.toLocaleDateString("de-DE", options) + " " + date.toLocaleTimeString("de-DE", timeOptions) + " Uhr";

  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  nl2br(value) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
};

appInstance.mount("#app");

