<template>
  <div class="draft-item headline">
  <div class="draftLineWrap" >
    <!--<div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>-->
    <div class="lineitem l-title">
      <span @click="emitSort('name')"  :class="(sortDirections.name) ? 'active' : ''">Name</span>
      <i v-if="sortDirections.name === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.name === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-color" >
      <span @click="emitSort('color')"  :class="(sortDirections.color) ? 'active' : ''">Farbe</span>
      <i v-if="sortDirections.color === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.color === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('ordering')" :class="(sortDirections.ordering) ? 'active' : ''">Reihenfolge</span>
      <i v-if="sortDirections.ordering === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.ordering === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('use_as_default')" :class="(sortDirections.use_as_default) ? 'active' : ''">Standard</span>
      <i v-if="sortDirections.use_as_default === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.use_as_default === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ml-auto">

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem l-avatar" v-if="!$store.state.filters.documents.owner.value"></div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        name: '',
        color: '',
        icon: '',
        ordering: '',
        use_as_default: '',
        id: '',
      }

    }
  },
  props: {
    document: Object,
  },
  computed: {
    ...mapGetters(["documentActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
