<template>
  <div class="card-body draft-list">
    <p class="text-sm text-secondary" v-if="!draftList || draftList.data.length === 0">Es wurden keine Vorlagen
        gefunden.</p>

    <Details  v-if="draftActive && draftActive.id" :product="draftActive" />
    <Sort @sort="resortOrder($event)" />

      <div  class="draft-item" v-for="draft in draftList.data"
           :key="draft.id">
        <Headline :draft="draft"/>
      </div>


    <pagination list="drafts" listName="draftList" filterName="draftsFilter"  />

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Drafts/Details";
import Headline from "@/routing/core/Drafts/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Drafts/Layouts/Sort.vue";

export default {
  name: "DraftSearch",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  data() {
    return {
      selecteddraft: null,
      activedraft: null,
      draftCategories: [],
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
    };
  },

  components: {
    Pagination,
    Headline,
    Details,
    Sort,
  },

  methods: {
    getDrafts(){
      this.$store.dispatch("draftsFilter");

    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.drafts.ordering.value = payload.type;
        this.$store.state.filters.drafts.direction.value = payload.direction;
      } else {
        this.$store.state.filters.drafts.ordering.value = payload.type
      }
      this.getDrafts();
    },
  },

  created() {

    this.$store.dispatch("draftsFilter");
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("draftsFilter");
    },
    draftActive() {
    }
  }

};
</script>
