<template>
  <div class="productItem activeProduct">

    <div class="card">
      <h6 class="card-header"><i class="fa-duotone fa-square-info"></i> Produktdetails
        <button class="btn-close float-end" @click="closeProduct"  ></button>
        <div class="btn-group float-end">
          <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteProduct()" v-if="p.id"><i
            class="fa-duotone fa-trash"></i> Löschen
          </button>

          <button type="button" class="btn btn-outline-primary me-3 btn-sm" @click="updateProduct()"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="productName">Produktname</label>
              <input v-model="p.p_title" type="text" class="form-control" name="productName" id="productName">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="productCategory">Kategorie</label>
              <select class="form-select" v-model="p.p_catid">
                <option v-for="option in getConfigs.categories_products" :value="option.id">
                  {{ option.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="productState">Status</label>
              <select class="form-select" name="productState" id="productState">
                <option>Veröffentlicht</option>
                <option>Versteckt</option>
                <option>Archiviert</option>
                <option>Papierkorb</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="productNumber">Produktnummer</label>
              <input v-model="p.p_number" type="text" class="form-control" name="productNumber" id="productNumber">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="productEAN">EAN</label>
              <input v-model="p.p_ean" type="text" class="form-control" name="productEAN" id="productEAN">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label for="productQuantity">Anzahl</label>
            <input v-model="p.p_quantity" type="number" class="form-control" name="productQuantity"
                   id="productQuantity">
          </div>
          <div class="col-4">
            <label for="productPrice">Preis</label>
            <input v-model="p.p_price" type="text" class="form-control" name="productPrice" id="productPrice">
          </div>
          <div class="col-4">
            <label for="productUnit">Einheit</label>
            <input v-model="p.p_unit" type="text" class="form-control" name="productUnit" id="productUnit">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Produktbeschreibung</label>
            <!--<textarea v-model="p.p_desc" class="form-control" name="productDescription" id="productDescription" rows="8"></textarea>-->
            <Editor2 v-model="p.p_desc"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Interne Informationen</label>
            <Editor2 v-model="p.p_intern_info"/>
          </div>
          <div class="col-6">
            <label>Öffentliche Informationen</label>
            <Editor2 v-model="p.p_order_info"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Products/Layouts/Headline.vue";

export default {
  name: "ProductDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "productActive", "getConfigs"])
  },
  props: {
    product: {},
  },
  data() {
    return {
      p: {},
      isNew: true
    };
  },
  methods: {
    updateProduct() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.isNew) {
        axios.post(this.$store.state.apiUrl + "product/", VueInner.p, {headers}).then(() => {

          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gespeichert');
          // Load Products
          VueInner.closeProduct();
          VueInner.$store.dispatch('loadProducts');
        }).catch((r) => {
        });
      } else {
        axios.put(this.$store.state.apiUrl + "product/" + VueInner.p.id, VueInner.p, {headers}).then(() => {
          // close
          VueInner.closeProduct();
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('loadProducts');
        }).catch((r) => {
        });
      }
    },
    deleteProduct() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "product/" + VueInner.p.id, {headers}).then(() => {
        // close
        VueInner.closeProduct();
        // dispatch toastSuccess
        VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gelöscht');
        // Load Products
        VueInner.$store.dispatch('loadProducts');
      }).catch((r) => {
      });
    },
    closeProduct(){
      this.$store.commit('closeProduct');
      this.$store.commit('closeModal', 'product');
    } ,
    checkIfIsNew() {
      if (this.productActive && this.productActive.id) {
        this.p = this.productActive;
        this.isNew = false;
      } else {
        this.p = {};
        this.isNew = true;
      }
    }
  },
  created() {
    this.checkIfIsNew();
  },
};
</script>
