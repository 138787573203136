<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="addOwners-read-none" autocomplete="off"
                 v-model="e.rights.addOwners">
          <label class="btn btn-outline-primary" for="addOwners-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="addOwners-read-yes" autocomplete="off"
                 v-model="e.rights.addOwners">
          <label class="btn btn-outline-primary" for="addOwners-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>Darf Mitarbeiter Zugriffsrechte konfigurieren</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("globals.access") }}</h6>
        <div class="btn-group me-3" v-if="e.rights && e.rights.products">
          <input type="radio" value="none" class="btn-check" id="products-read-none" autocomplete="off"
                 v-model="e.rights.products.read">
          <label class="btn btn-outline-primary" for="products-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="products-read-yes" autocomplete="off"
                 v-model="e.rights.products.read">
          <label class="btn btn-outline-primary" for="products-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.products") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="config-read-none" autocomplete="off"
                 v-model="e.rights.config.read">
          <label class="btn btn-outline-primary" for="config-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="config-read-yes" autocomplete="off"
                 v-model="e.rights.config.read">
          <label class="btn btn-outline-primary" for="config-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.settings") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="times-read-none" autocomplete="off"
                 v-model="e.rights.times.read">
          <label class="btn btn-outline-primary" for="times-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="times-read-yes" autocomplete="off"
                 v-model="e.rights.times.read">
          <label class="btn btn-outline-primary" for="times-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.workflow") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="activities-read-none" autocomplete="off"
                 v-model="e.rights.activities.read">
          <label class="btn btn-outline-primary" for="activities-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="activities-read-yes" autocomplete="off"
                 v-model="e.rights.activities.read">
          <label class="btn btn-outline-primary" for="activities-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.activities") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="dashboard-read-none" autocomplete="off"
                 v-model="e.rights.dashboard.read">
          <label class="btn btn-outline-primary" for="dashboard-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="dashboard-read-yes" autocomplete="off"
                 v-model="e.rights.dashboard.read">
          <label class="btn btn-outline-primary" for="dashboard-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.dashboard") }}</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("globals.employee") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-read-none" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-primary" for="employee-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="employee-read-own" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-primary" for="employee-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-read-all" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-primary" for="employee-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-create-none" autocomplete="off"
                 v-model="e.rights.employees.create">
          <label class="btn btn-outline-primary" for="employee-create-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="employee-create-own" autocomplete="off"
                 v-model="e.rights.employees.create">
          <label class="btn btn-outline-primary" for="employee-create-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-create-all" autocomplete="off"
                 v-model="e.rights.employees.create">
          <label class="btn btn-outline-primary" for="employee-create-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-update-none" autocomplete="off"
                 v-model="e.rights.employees.update">
          <label class="btn btn-outline-primary" for="employee-update-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="employee-update-own" autocomplete="off"
                 v-model="e.rights.employees.update">
          <label class="btn btn-outline-primary" for="employee-update-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-update-all" autocomplete="off"
                 v-model="e.rights.employees.update">
          <label class="btn btn-outline-primary" for="employee-update-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-delete-none" autocomplete="off"
                 v-model="e.rights.employees.delete">
          <label class="btn btn-outline-primary" for="employee-delete-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="employee-delete-own" autocomplete="off"
                 v-model="e.rights.employees.delete">
          <label class="btn btn-outline-primary" for="employee-delete-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-delete-all" autocomplete="off"
                 v-model="e.rights.employees.delete">
          <label class="btn btn-outline-primary" for="employee-delete-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("acl.customerDataSets") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-read-none" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-primary" for="customers-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-read-own" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-primary" for="customers-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-read-all" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-primary" for="customers-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-create-none" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-primary" for="customers-create-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-create-own" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-primary" for="customers-create-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-create-all" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-primary" for="customers-create-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-update-none" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-primary" for="customers-update-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-update-own" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-primary" for="customers-update-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-update-all" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-primary" for="customers-update-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-delete-none" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-primary" for="customers-delete-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-delete-own" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-primary" for="customers-delete-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-delete-all" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-primary" for="customers-delete-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
    </div>
    <div class="col-12 col-lg-6">

      <div class="form-group">
        <h6>{{ $t("globals.orders") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-read-none" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-primary" for="documents-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-read-own" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-primary" for="documents-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-read-all" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-primary" for="documents-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-create-none" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-primary" for="documents-create-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-create-own" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-primary" for="documents-create-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-create-all" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-primary" for="documents-create-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-update-none" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-primary" for="documents-update-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-update-own" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-primary" for="documents-update-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-update-all" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-primary" for="documents-update-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-delete-none" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-primary" for="documents-delete-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-delete-own" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-primary" for="documents-delete-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-delete-all" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-primary" for="documents-delete-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("acl.salesRelatedness") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentsSales-delete-none" autocomplete="off"
                 v-model="e.rights.documents.umsatz">
          <label class="btn btn-outline-primary" for="documentsSales-delete-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentsSales-delete-yes" autocomplete="off"
                 v-model="e.rights.documents.umsatz">
          <label class="btn btn-outline-primary" for="documentsSales-delete-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentsSales-read-none" autocomplete="off"
                 v-model="e.rights.documents.umsatzs">
          <label class="btn btn-outline-primary" for="documentsSales-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentsSales-read-yes" autocomplete="off"
                 v-model="e.rights.documents.umsatzs">
          <label class="btn btn-outline-primary" for="documentsSales-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.salesAccessRead") }}</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("acl.statisticAccess") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="statistic-read-none" autocomplete="off"
                 v-model="e.rights.statistic">
          <label class="btn btn-outline-primary" for="statistic-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="statistic-read-yes" autocomplete="off"
                 v-model="e.rights.statistic">
          <label class="btn btn-outline-primary" for="statistic-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.employeeStatisticAccess") }}</label>
      </div>
      <div class="form-group">
        <h6>{{ $t("acl.accountingAccess") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentBox-read-none" autocomplete="off"
                 v-model="e.rights.documentbox">
          <label class="btn btn-outline-primary" for="documentBox-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentBox-read-yes" autocomplete="off"
                 v-model="e.rights.documentbox">
          <label class="btn btn-outline-primary" for="documentBox-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.employeeAccountingAccess") }}</label>
      </div>
    </div>
  </div>
  <hr>
</template>
<script>
import DocumentItem from "@/views/components/document/DocumentItem.vue";

export default {
  name: "Acl",
  components: {DocumentItem},
  props: {
    employee: {},
  },
  emits: ["acl-changed"],
  data: function () {
    return {
      e: {
        rights: {
          addOwners: 'no',
          products: {
            read: "none"
          },
          config: {
            read: "none"
          },
          times: {
            read: "none"
          },
          activities: {
            read: "none"
          },
          dashboard: {
            read: "none"
          },
          employees: {
            read: "none",
            create: "none",
            update: "none",
            delete: "none"
          },
          customers: {
            read: "all",
            create: "none",
            update: "none",
            delete: "none"
          },
          accessJoomla: {
            read: "none"
          },
          documents: {
            read: "none",
            create: "none",
            update: "none",
            delete: "none",
            umsatz: 'no',
            umsatzs: 'no'
          },

          statistic: 'no',
          documentbox: 'no',
        }
      },
    }
  },
  created() {
    console.log(this.employee);
    console.log("created");
    if (this.employee && this.employee.rights && this.employee.rights.config) {
      this.e.rights = this.employee.rights;
    }
  },
  methods: {
    manipulateRights() {
      console.log("aa");
      this.$emit('acl-changed', this.e.rights);
    },
  },
  watch: {
    "e.rights": {
      deep: true,
      handler() {
        this.manipulateRights();
      },
    },
  },
}
</script>
<style scoped>
</style>
