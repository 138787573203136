<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Vorlage</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeDraft');$store.commit('closeModal', 'category');$router.push({name: 'categories'})">
        </button>
        <div class="btn-group float-end">


          <button type="button" class="btn btn-outline-danger btn-sm" v-if="d.id" @click="deleteDraft()" :disabled="d.isSystemDraft === 'yes'"><i
            class="fa-duotone fa-trash"></i> Löschen
          </button><button type="button" class="btn btn-outline-primary btn-sm me-4" @click="updateDraft()"><i
          class="fa-duotone fa-floppy-disk"></i> Speichern
        </button></div>
      </div>
      <div class="card-body">
        <p v-if="d.isSystemDraft === 'yes'"><i class="fa-duotone fa-info-circle"></i> Die Systemvorlage kann nicht gelöscht werden</p>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Bezeichnung</label>
              <input class="form-control" type="text" v-model="d.title">
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Kategorie</label>
              <select v-model="d.group_id" class="form-select">
                <option :value="cat.id" v-for="cat in getConfigs.categories_drafts" :key="cat.id">{{
                    cat.title
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Sortierung</label>
              <input class="form-control" type="number" v-model="d.ordering">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Text</label>
          <Editor2 v-model="d.draft"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Categories/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "DraftDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "categoryActive", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {
    updateDraft() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "draft/" + VueInner.d.id, VueInner.d, {headers}).then(() => {
          VueInner.$store.commit('closeDraft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Draft wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
          this.$router.push({name: 'drafts'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "draft", VueInner.d, {headers}).then(() => {
          VueInner.$store.commit('closeDraft');
          this.$store.commit('closeModal', 'draft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
          this.$router.push({name: 'drafts'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteDraft() {
      let VueInner = this;
      this.$confirm('Soll die Vorlage wirklich gelöscht werden?','Vorlage löschen', "question",  {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {

        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "draft/" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeDraft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gelöscht');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
     this.d = this.$store.getters.categoryActive;
      if (this.d.id !== undefined) {
        this.isNew = false;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
<style scoped>
h6.inline{
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
